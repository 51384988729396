import { useSelector } from 'react-redux';
import { selectCarModel } from '../../../redux/slices/carModelSlices';
import { selectUsedCars } from '../../../redux/slices/usedCarsSlices';
import { selectNewCars } from '../../../redux/slices/newCarsSlisec';

import { useEffect } from 'react';
import { CarsPricesBlock } from './CarsPricesBlock';
import { CarsInfoBlock } from './CarsInfoBlock';
import { NewCarsBox } from '../../Home/Products/Cars/NewCarsBox';
import { ImHome } from 'react-icons/im';
import { Link } from 'react-router-dom';
import { IoCarSportSharp } from 'react-icons/io5';
import { CarGallery } from './CarGallery';

export const CarsModelPage = () => {
  const car = useSelector(selectCarModel);
  const allCars = [
    ...useSelector(selectNewCars),
    ...useSelector(selectUsedCars),
  ];

  const filterCars = allCars.filter((cars) => {
    return cars.category === car.category && cars.idn !== car.idn;
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [allCars]);

  return car === false ? (
    <div className="loader h-[500px]"></div>
  ) : (
    <div>
      <div className="page-presentation-cars relative">
        <div className="container">
          <div className="title text-center">
            <h1>{car.carsName}</h1>
          </div>
          <div className="links">
            <ul className="flex">
              <li className="link flex items-center">
                <ImHome className="mr-[5px]" />
                <Link to="/">Главная</Link>
              </li>
              <li className="link flex items-center">
                / <IoCarSportSharp className="mr-[5px] ml-[10px]" />
                <Link to="/models">Каталог автомобилей</Link>
              </li>
              <li className="flex items-center">
                / <IoCarSportSharp className="mr-[5px] ml-[10px]" />
                <span>{car.carsName}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="car-model">
          <CarGallery car={car} />
          <CarsInfoBlock car={car} />
          <CarsPricesBlock car={car} />
        </div>
        {filterCars != false ? (
          <div className="other-models">
            <div className="title">
              <h2>
                Модельный ряд <span>{car.category}</span>
              </h2>
            </div>
            <NewCarsBox filterCars={filterCars} />
          </div>
        ) : (
          false
        )}
      </div>
    </div>
  );
};
