import axios from 'axios';
import {
  TbCircleNumber1Filled,
  TbCircleNumber2Filled,
  TbCircleNumber3Filled,
  TbCircleNumber4Filled,
} from 'react-icons/tb';
import { useEffect, useState } from 'react';
import './Services.css';

export const ServicesSection = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const restData = await axios.get('/api/4');
        const data = restData.data;
        setData(data);
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);

  return (
    <section className="services" id="services">
      <div className="container">
        <div className="title">
          Как мы <span>работаем</span>
        </div>
        <div className="services-content-section mt-[40px]">
          {data == false ? (
            <div className="loader h-[303px]"></div>
          ) : (
            <div className="services-content">
              <div className="timeline">
                {data.map((item, index) => (
                  <div className="container-box" key={item.id}>
                    <span className={`span-${item.id}`}>
                      {index === 0 ? (
                        <TbCircleNumber1Filled />
                      ) : index === 1 ? (
                        <TbCircleNumber2Filled />
                      ) : index === 2 ? (
                        <TbCircleNumber3Filled />
                      ) : (
                        <TbCircleNumber4Filled />
                      )}
                    </span>
                    <div className={`text-box box-${item.id}`}>
                      <h2>{item.title}</h2>
                      <p>{item.description}</p>
                      <span className={`container-box-arrow-${item.id}`}></span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
