import { FaHandFist, FaCheck, FaTruck, FaLocationDot } from 'react-icons/fa6';
import aboutImg from './img/car.png';
export const AboutSection = () => {
  return (
    <section className="about-section">
      <div className="container">
        <div className="about-content">
          <div className="about-boxs">
            <div className="about-box">
              <div className="about-icon">
                <FaCheck className="icon" />
              </div>
              <div className="about-text ml">
                <p>
                  Мы осуществляем покупку, таможенную очистку и доставку
                  автомобилей в Россию.
                </p>
              </div>
            </div>
            <div className="about-box mt-[40px]">
              <div className="about-icon">
                <FaHandFist className="icon " />
              </div>
              <div className="about-text ml">
                <p>
                  Мы контролируем каждую цепочку в покупке авто и его логистике.
                  Без посредников.
                </p>
              </div>
            </div>
          </div>
          <div className="about-img">
            <img src={aboutImg} alt="neofical" className="max-w-[100%]" />
          </div>
          <div className="about-boxs">
            <div className="about-box">
              <div className="about-text mr">
                <p className="text-end">
                  Именно поэтому наши автомобили всегда приезжают быстрее и
                  стоят дешевле чем у конкурентов.
                </p>
              </div>
              <div className="about-icon">
                <FaTruck className="icon" />
              </div>
            </div>
            <div className="about-box mt-[40px]">
              <div className="about-text mr">
                <p className="text-end">
                  Также мы продаём автомобили в наличии в нашем шоуруме по
                  адресу: г. Казань. ул. Седова 24к1
                </p>
              </div>
              <div className="about-icon">
                <FaHandFist className="icon" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
