import { ContactForm } from '../ContactForm';
import './ContactForm.css';

import 'react-toastify/dist/ReactToastify.css';

export const FormSection = ({ title, span }) => {
  return (
    <div className="form-section">
      <div className="container">
        <div className="form-section-content">
          <div className="form-title">
            <div className="form-title-h1">
              {title} <span>{span}</span>
            </div>
            <h3>
              Звоните по <a href="tel:+78047004041"> +7(804)700-40-41</a> или
              отправьте заявку и мы Вам перезвоним
            </h3>
          </div>
          <div className="form-section-inputs">
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  );
};
