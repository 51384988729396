export const PartsInfoBlock = ({ part }) => {
  return (
    <div className="part-info-block">
      <div className="part-info">
        <h2>{part.title}</h2>
        <div className="car-faq-info">
          <h3>О товаре</h3>
          <table>
            <thead>
              {part.type !== null ? (
                <tr>
                  <td>Тип:</td>
                  <td>{part.type}</td>
                </tr>
              ) : (
                false
              )}
              <tr>
                <td>Для моделей:</td>
                <td>{part.forModel}</td>
              </tr>
              <tr>
                <td>Оригинальность:</td>
                <td>{part.originality}</td>
              </tr>
              {part.color !== null ? (
                <tr>
                  <td>Цвет:</td>
                  <td>{part.color}</td>
                </tr>
              ) : (
                false
              )}
              <tr>
                <td>Состояние:</td>
                <td>{part.state}</td>
              </tr>
              <tr>
                <td>Производитель:</td>
                <td>{part.manufacturer}</td>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
        {part.description !== null ? (
          <p className="mt-[10px] text-gray-800 text-[14px]">
            {part.description}
          </p>
        ) : (
          false
        )}
      </div>
    </div>
  );
};
