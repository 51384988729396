import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import defImg from '../../../img/image.png';

export const CarGallery = ({ car }) => {
  const carImgs = [
    car.carsImg,
    car.carsImg2,
    car.carsImg3,
    car.carsImg4,
    car.carsImg5,
    car.carsImg6,
    car.carsImg7,
    car.carsImg8,
  ];

  const imgs = [];
  carImgs.filter((img) => {
    if (img !== null) {
      return imgs.push({
        src: '../img/cars/' + img,
      });
    }
  });

  return (
    <div className="car-imgs-block">
      <Carousel images={imgs} />
    </div>
  );
};
