import axios from 'axios';
import { useState } from 'react';
import './modal.css';
import { Link } from 'react-router-dom';
import { useMask } from '@react-input/mask';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const ContactForm = () => {
  const [buttonLoanding, setButtonLoanding] = useState(false);
  const [values, setValues] = useState({
    phone: '',
    policy: false,
  });

  const notify = () =>
    toast.success('Заявка успешно отправлена', {
      autoClose: 2000,
      position: 'top-left',
    });

  const notifyError = () =>
    toast.error('Сообщение не отправлено. Повторите попытку', {
      autoClose: 2000,
      position: 'top-left',
    });

  const phoneRef = useMask({
    mask: '+7 (___) ___-__-__',
    replacement: { _: /\d/ },
  });

  const getInputData = (event, name) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    const TOKEN = '7968206331:AAE3e3ObibGeYLKj63RjjP8Abbh9Vvor5dk';
    const CHAT_ID = '-1002379583102';
    const URL_API = `https://api.telegram.org/bot${TOKEN}/sendMessage`;

    let message = `<b>Телефон:</b> ${values.phone}\n`;
    setButtonLoanding(!buttonLoanding);
    try {
      await axios.post(URL_API, {
        chat_id: CHAT_ID,
        parse_mode: 'html',
        text: message,
      });
      notify();

      setValues({ phone: '', policy: false });
      setButtonLoanding(false);
    } catch (error) {
      notifyError();
      setButtonLoanding(false);
    }
  };

  return (
    <div className="form-content">
      <form onSubmit={onSubmit}>
        <div className="input-box">
          <input
            className="field"
            type="tel"
            name="Телефон"
            value={values.phone}
            ref={phoneRef}
            onChange={(e) => getInputData(e, 'phone')}
            placeholder="+7(___)___-__-__"
            required
          />
        </div>
        <p className="mt-[10px]">
          <input
            type="checkbox"
            required
            value={values.policy}
            onChange={(e) => setValues({ ...values, policy: e.target.checked })}
            name="Согласен на обработку персональных данных"
          />
          <span> Согласен на обработку </span>
          <Link to="privacy" className="decoration-solid decoration-slice">
            персональных данных
          </Link>
        </p>
        <button type="submit">
          {!buttonLoanding ? (
            'Жду звонка'
          ) : (
            <div className="loader-2 h-[20px]"></div>
          )}
        </button>
      </form>
    </div>
  );
};
