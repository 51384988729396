import { configureStore } from '@reduxjs/toolkit';
import newCarsSlisec from '../slices/newCarsSlisec';
import usedCarsSlices from '../slices/usedCarsSlices';
import partsSlices from '../slices/partsSlices';
import favoriteSlices from '../slices/favoriteSlices';
import conutCarsSlices from '../slices/conutCarsSlices';
import countPartsSlices from '../slices/countPartsSlices';
import carModelSlices from '../slices/carModelSlices';
import partModelSlices from '../slices/partModelSlices';
import modalToggleSlices from '../slices/modalToggleSlices';

const store = configureStore({
  reducer: {
    newCars: newCarsSlisec,
    usedCars: usedCarsSlices,
    parts: partsSlices,
    favoriteCars: favoriteSlices,
    countCars: conutCarsSlices,
    countParts: countPartsSlices,
    carModel: carModelSlices,
    partModel: partModelSlices,
    toggleModal: modalToggleSlices,
  },
});

export default store;
