// contac icon
import { FiPhone } from 'react-icons/fi';
import { FaRegClock } from 'react-icons/fa';
import { GoLocation } from 'react-icons/go';

import { FaExchangeAlt } from 'react-icons/fa';
import { RiInformation2Line } from 'react-icons/ri';
import { BiCheckCircle, BiSolidHourglass } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { selectFavoriteCars } from '../../../redux/slices/favoriteSlices';
import {
  selectToggleModal,
  toggleForm,
} from '../../../redux/slices/modalToggleSlices';
import { addFavoriteCar } from '../../../redux/slices/favoriteSlices';
import { Button } from '../../UI/Button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const CarsPricesBlock = ({ car }) => {
  const dispatch = useDispatch();
  const favoriteCars = useSelector(selectFavoriteCars);
  const toggleModal = useSelector(selectToggleModal);

  const toggleFormModal = () => {
    dispatch(toggleForm());
    !toggleModal
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'visible');
  };

  const notify = () =>
    toast.success('Успешно добавлено в сравнение', {
      autoClose: 2000,
      position: 'top-left',
    });

  const notifyInfo = () =>
    toast.info('Уже добавлено', {
      autoClose: 2000,
      position: 'top-left',
    });

  const addCarInFavorite = (car) => {
    let isInArray = false;
    favoriteCars.forEach((date) => {
      if (date.idn === car.idn) {
        isInArray = true;
      }
    });
    if (!isInArray) {
      dispatch(addFavoriteCar(car));
      notify();
    } else {
      notifyInfo();
    }
  };

  return (
    <div className="car-praice-block w-[100%]">
      <div className="praice-top-buttons">
        {car.inner.toLowerCase() === 'в наличии' ? (
          <div className="price-inner">
            <span>{car.inner}</span>
          </div>
        ) : car.inner.toLowerCase() === 'в пути' ? (
          <div className="price-inner-3">
            <span>{car.inner}</span>
          </div>
        ) : (
          <div className="price-inner-2">
            <span>{car.inner}</span>
          </div>
        )}
        <FaExchangeAlt
          className="actions-icon"
          onClick={() => addCarInFavorite(car)}
        />
      </div>
      <div className="price mt-[20px] mb-[20px]">
        <h2>{car.carsName}</h2>
        <h3>{car.carsPrice}</h3>
        <p className="mt-[10px] flex items-center">
          <RiInformation2Line className="price-info" />
          {car.inner.toLowerCase() !== 'в наличии' ? (
            <span className="ml-[5px]">Цена под заказ</span>
          ) : (
            <span className="ml-[5px]">Цена в наличии</span>
          )}
        </p>
        <p className="mt-[10px] flex items-center">
          <BiSolidHourglass className="price-info" />
          <span className="ml-[5px]">Срок поставки: 60 дней</span>
        </p>
        <p className="mt-[10px] flex items-center">
          <BiCheckCircle className="price-info" />
          <span className="ml-[5px]">Под ключ с таможеной РФ</span>
        </p>
        <div className="model-contact">
          <h4>Контакты</h4>
          <ul>
            <li>
              <FiPhone className="icon-bar" />
              <a href="tel:+78047004041">+7(804)700-40-41</a>
            </li>
            <li>
              <FaRegClock className="icon-bar" />
              <span>Пн - Вс 10:00 - 20:00</span>
            </li>
            <li>
              <GoLocation className="icon-bar" />
              <a
                href="https://yandex.ru/maps/org/neofitsal_avto/110534909234/?ll=49.180748%2C55.788152&z=18"
                target="_blank"
              >
                г. Казань. ул. Седова 24к1
              </a>
            </li>
          </ul>
        </div>
        <div className="flex justify-center" onClick={toggleFormModal}>
          <Button url="" title="Отправить заявку" />
        </div>
      </div>
    </div>
  );
};
