import { useState } from 'react';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa6';
import { PiEngineBold, PiCarSimpleBold, PiGearSixBold } from 'react-icons/pi';

export const CarsInfoBlock = ({ car }) => {
  const [accordionOpen, setAccordionOpen] = useState(0);

  const updateAccor = (n) => {
    setAccordionOpen(n);
  };

  return (
    <div className="car-info-block">
      {/* 1 */}
      <div className="car-faq-info">
        <div className="py-1">
          <button onClick={() => updateAccor(0)}>
            <h3>
              <PiGearSixBold className="icon" />
              <span>Основные характеристики</span>
            </h3>
            <span>
              <span>
                {accordionOpen === 0 ? <FaAngleUp /> : <FaAngleDown />}
              </span>
            </span>
          </button>
          <div
            className={
              accordionOpen === 0
                ? 'car-info-content active'
                : 'car-info-content'
            }
          >
            <div className="overflow-hidden">
              <table>
                <thead>
                  <tr>
                    <td>Год</td>
                    <td>{car.carsDate}</td>
                  </tr>
                  <tr>
                    <td>Цвет</td>
                    <td>{car.colors}</td>
                  </tr>
                  <tr>
                    <td>Комплектация</td>
                    <td>MAX</td>
                  </tr>
                  <tr>
                    <td>Плагин</td>
                    <td>{car.carsType}</td>
                  </tr>
                  <tr>
                    <td>Пробег</td>
                    <td>{car.mileage}</td>
                  </tr>
                  <tr>
                    <td>Коробка предач</td>
                    <td>{car.transmission}</td>
                  </tr>
                  <tr>
                    <td>Привод</td>
                    <td>{car.carsDrive}</td>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* 2 */}
      <div className="car-faq-info mt-[20px]">
        <div className="py-1">
          <button onClick={() => updateAccor(1)}>
            <h3>
              <PiEngineBold className="icon" /> <span>Двигатель</span>
            </h3>
            <span>{accordionOpen === 1 ? <FaAngleUp /> : <FaAngleDown />}</span>
          </button>
          <div
            className={
              accordionOpen === 1
                ? 'car-info-content active'
                : 'car-info-content'
            }
          >
            <div className="overflow-hidden">
              <table>
                <thead>
                  <tr>
                    <td>Тип двигателя</td>
                    <td>{car.carsType}</td>
                  </tr>
                  <tr>
                    <td>Объем двигателя</td>
                    <td>{car.carsEngene}</td>
                  </tr>
                  <tr>
                    <td>Мощность л.с</td>
                    <td>{car.power}</td>
                  </tr>
                  <tr>
                    <td>Смешанный расход</td>
                    <td>{car.consumption}</td>
                  </tr>
                  <tr>
                    <td>Запас хода на электро</td>
                    <td>{car.electricRange}</td>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* 3 */}
      <div className="car-faq-info mt-[20px]">
        <div className="py-1">
          <button onClick={() => updateAccor(2)}>
            <h3>
              <PiCarSimpleBold className="icon" /> <span>Габариты авто</span>
            </h3>
            <span>{accordionOpen === 2 ? <FaAngleUp /> : <FaAngleDown />}</span>
          </button>
          <div
            className={
              accordionOpen === 2
                ? 'car-info-content active'
                : 'car-info-content'
            }
          >
            <div className="overflow-hidden">
              <table>
                <thead>
                  <tr>
                    <td>Тип кузова</td>
                    <td>{car.bodyType}</td>
                  </tr>
                  <tr>
                    <td>Длина</td>
                    <td>{car.carsLenght}</td>
                  </tr>
                  <tr>
                    <td>Ширина</td>
                    <td>{car.carsHeight}</td>
                  </tr>
                  <tr>
                    <td>Высота</td>
                    <td>{car.carsWidth}</td>
                  </tr>
                  <tr>
                    <td>Масса</td>
                    <td>{car.carsWeight}</td>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
