import { MdDelete } from 'react-icons/md';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
// import required modules
import { Navigation, Pagination } from 'swiper/modules';
import { Button } from '../../../../UI/Button';
import { useSelector, useDispatch } from 'react-redux';
import {
  toggleForm,
  selectToggleModal,
} from '../../../../../redux/slices/modalToggleSlices';

export const CompBox = ({ cars, deleteCar }) => {
  const dispatch = useDispatch();
  const toggleModal = useSelector(selectToggleModal);

  const toggleFormModal = () => {
    dispatch(toggleForm());
    !toggleModal
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'visible');
  };

  return (
    <div className="compericon-items">
      <Swiper
        slidesPerView={
          window.innerWidth <= 700
            ? 2
            : window.innerWidth <= 1050
            ? 2
            : window.innerWidth <= 1250
            ? 3
            : window.innerWidth <= 1550
            ? 4
            : window.innerWidth > 1250
            ? 4
            : 0
        }
        spaceBetween={20}
        grabCursor={true}
        loop={true}
        navigation={true}
        pagination={{
          dynamicBullets: true,
        }}
        modules={[Navigation, Pagination]}
        className="mySwiper"
      >
        {cars.map((car) => (
          <SwiperSlide key={car.idn}>
            <div className="comparicom-box" key={car.idn}>
              <div className="comp-header">
                <h3>{car.carsName}</h3>
                <div className="comp-icon" onClick={() => deleteCar(car.idn)}>
                  <MdDelete className="icon" />
                </div>
              </div>
              <div className="comp-img">
                <img src={'../img/cars/' + car.carsImg} alt="" />
              </div>
              <div className="comp-infos">
                <div className="comp-info">
                  <span className="copm-title">
                    <b>Цена</b>
                  </span>
                  <hr />
                  <span>{car.carsPrice}</span>
                </div>
                <div className="comp-info">
                  <span className="copm-title">
                    <b>Цвет</b>
                  </span>
                  <hr />
                  <span>{car.colors}</span>
                </div>
                <div className="comp-info">
                  <span className="copm-title">
                    <b>Год</b>
                  </span>
                  <hr />
                  <span>{car.carsDate}</span>
                </div>
                <div className="comp-info">
                  <span className="copm-title">
                    <b>Тип кузова</b>
                  </span>
                  <hr />
                  <span>{car.bodyType}</span>
                </div>
                <div className="comp-info">
                  <span className="copm-title">
                    <b>Пробег</b>
                  </span>
                  <hr />
                  <span>{car.mileage}</span>
                </div>
                <div className="comp-info">
                  <span className="copm-title">
                    <b>Тип двигателя</b>
                  </span>
                  <hr />
                  <span>{car.carsType}</span>
                </div>
                <div className="comp-info">
                  <span className="copm-title">
                    <b>Объем двигателя</b>
                  </span>
                  <hr />
                  <span>{car.carsEngene}</span>
                </div>
                <div className="comp-info">
                  <span className="copm-title">
                    <b>Привод</b>
                  </span>
                  <hr />
                  <span>{car.carsDrive}</span>
                </div>
                <div className="comp-info">
                  <span className="copm-title">
                    <b>Мощность, л.с</b>
                  </span>
                  <hr />
                  <span>{car.power}</span>
                </div>
                <div className="comp-info">
                  <span className="copm-title">
                    <b>Коробка передач</b>
                  </span>
                  <hr />
                  <span>{car.transmission}</span>
                </div>
                <div className="comp-info">
                  <span className="copm-title">
                    <b>Смешанный расход</b>
                  </span>
                  <hr />
                  <span>{car.consumption}</span>
                </div>
                <div className="comp-info">
                  <span className="copm-title">
                    <b>Запас хода на электротяге</b>
                  </span>
                  <hr />
                  <span>{car.electricRange}</span>
                </div>
              </div>
              <div className="com-btn" onClick={() => toggleFormModal()}>
                <Button url="" title="заказать" />
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
