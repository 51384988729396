import { Link } from 'react-router-dom';

export const Button = ({ url, title }) => {
  return (
    <Link to={url} className="button w-[200px]">
      <span className="btn-content">
        <span className="btn-arrow">&#10230;</span>
        <span className="btn-text">{title}</span>
      </span>
    </Link>
  );
};
