import { createSlice } from '@reduxjs/toolkit';

const initialState = false;

const modaltoggleSlices = createSlice({
  name: 'toggleModal',
  initialState,
  reducers: {
    toggleForm: (state) => {
      return (state = !state);
    },
  },
});
export const { toggleForm } = modaltoggleSlices.actions;
export const selectToggleModal = (state) => state.toggleModal;

export default modaltoggleSlices.reducer;
