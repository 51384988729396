import React, { useState } from 'react';
import { GiCityCar, GiRoad, GiAutoRepair } from 'react-icons/gi';
import { MdDirectionsCar } from 'react-icons/md';
import { NewCars } from './Cars/NewCars';
import { UsedCars } from './Cars/UsedCars';
import { Parts } from './Parts/Parts';

export const Products = () => {
  const [tabIsActive, setTab] = useState(1);

  const tabActive = (n) => {
    setTab(n);
  };

  return (
    <section className="products">
      <div className="tab-products-buttons">
        <div
          className={tabIsActive === 1 ? 'tab-button active' : 'tab-button'}
          onClick={() => tabActive(1)}
        >
          <div className="tab-icon">
            <GiCityCar />
          </div>
          <h2>Новые авто</h2>
          <p>Выгоднее чем у официалов</p>
        </div>
        <div
          className={tabIsActive === 2 ? 'tab-button active' : 'tab-button'}
          onClick={() => tabActive(2)}
        >
          <div className="tab-icon">
            <MdDirectionsCar />
          </div>
          <h2>C пробегом</h2>
          <p>Авто с пробегом из китая</p>
        </div>
        <div
          className={tabIsActive === 3 ? 'tab-button active' : 'tab-button'}
          onClick={() => tabActive(3)}
        >
          <div className="tab-icon">
            <GiAutoRepair />
          </div>
          <h2>Запчасти</h2>
          <p>Оригинальные запчасти для вашей модели</p>
        </div>
      </div>

      <div className="container">
        <div className="tabs-products-content">
          {tabIsActive === 1 ? (
            <NewCars />
          ) : tabIsActive === 2 ? (
            <UsedCars />
          ) : (
            <Parts />
          )}
        </div>
      </div>
    </section>
  );
};
