import { createSlice } from '@reduxjs/toolkit';

const initialState = 0;

const countPartsSlices = createSlice({
  name: 'countParts',
  initialState: initialState,
  reducers: {
    addPartsCount: (state, action) => {
      return (state = action.payload);
    },
  },
});

export const { addPartsCount } = countPartsSlices.actions;
export const selectCountParts = (state) => state.countParts;

export default countPartsSlices.reducer;
