import { useEffect } from 'react';
import errorimg from './error-img.png';
import { ImHome } from 'react-icons/im';
import { Link } from 'react-router-dom';

export const NotFound = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="notfond">
      <div className="notfond-presentation relative">
        <div className="container">
          <div className="title text-center">
            <h1>Станица не надена</h1>
          </div>
          <div className="links">
            <ul className="flex">
              <li className="link flex items-center">
                <ImHome className="mr-[5px]" />
                <Link to="/">Главная</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="notfond-content">
        <h1>404</h1>
        <img src={errorimg} alt="neofical" />
      </div>
    </div>
  );
};
