import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectNewCars } from '../../../../../redux/slices/newCarsSlisec';
import { selectUsedCars } from '../../../../../redux/slices/usedCarsSlices';
import { selectParts } from '../../../../../redux/slices/partsSlices';
import { addCar } from '../../../../../redux/slices/carModelSlices';
import { addPart } from '../../../../../redux/slices/partModelSlices';
import { FaXmark } from 'react-icons/fa6';
import { FiSearch } from 'react-icons/fi';

export const Search = ({ favoriteModal, toggleFavoriteModal }) => {
  const dispatch = useDispatch();
  const [input, setInput] = useState('');
  const [resData, setResData] = useState([]);

  const data = [
    ...useSelector(selectNewCars),
    ...useSelector(selectUsedCars),
    ...useSelector(selectParts),
  ];
  const feachData = (value) => {
    const results = data.filter((item) => {
      return (
        value &&
        item &&
        item.carsName &&
        item.carsName.toLowerCase().includes(value)
      );
    });
    const resultsParts = data.filter((item) => {
      return (
        value && item && item.title && item.title.toLowerCase().includes(value)
      );
    });

    setResData([...results, ...resultsParts]);
  };

  const handleChange = (value) => {
    setInput(value);
    feachData(value);
  };

  const highkightMatch = (text, filter) => {
    if (!filter) return text;

    const regex = new RegExp(`(${filter})`, 'gi');

    return text.split(regex).map((part, i) => {
      if (part.toLowerCase() === filter.toLowerCase()) {
        return (
          <span key={i} className="highlight">
            {part}
          </span>
        );
      }
      return part;
    });
  };

  const addCarModel = (car) => {
    dispatch(addCar(car));
  };
  const addPartModel = (part) => {
    dispatch(addPart(part));
  };

  return (
    <div
      className={favoriteModal ? 'modal-window close-modal' : 'modal-window'}
    >
      <div className="modal">
        <div className="modal-nav">
          <div className="modal-title">
            <h2>Поиск</h2>
          </div>
          <div className="modal-icon-close">
            <FaXmark
              className="close-modal-icon"
              onClick={() => toggleFavoriteModal()}
            />
          </div>
        </div>
        <div className="modal-content">
          <div className="modal-container w-[95%] m-auto min-h-[85vh]">
            <div className="search">
              <div className="search-input">
                <FiSearch className="search-icon" />
                <input
                  type="text"
                  placeholder="Поиск..."
                  value={input}
                  onChange={(e) => handleChange(e.target.value)}
                />
              </div>
              <div className="search-result-list">
                <div className="res-items">
                  <h3 className="mt-[10px] ml-[10px] font-semibold text-[#002c5f]">
                    Авто
                  </h3>
                  {resData.map((item, i) => (
                    <div key={i}>
                      {item.carsName ? (
                        <Link
                          to={`/models/${item.carsName}`}
                          onClick={() => addCarModel(item)}
                        >
                          <div
                            className="search-result"
                            onClick={() => toggleFavoriteModal()}
                          >
                            {highkightMatch(item.carsName, input)}
                          </div>
                        </Link>
                      ) : (
                        false
                      )}
                    </div>
                  ))}
                  <h3 className="mt-[10px] ml-[10px] font-semibold text-[#002c5f]">
                    Запчасти
                  </h3>
                  {resData.map((item, i) => (
                    <div key={i}>
                      {item.title ? (
                        <Link
                          to={`/parts/${item.title}`}
                          onClick={() => addPartModel(item)}
                        >
                          <div
                            className="search-result"
                            onClick={() => toggleFavoriteModal()}
                          >
                            {highkightMatch(item.title, input)}
                          </div>
                        </Link>
                      ) : (
                        false
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
