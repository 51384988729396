import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const favoriteSlice = createSlice({
  name: 'favoriteCars',
  initialState,
  reducers: {
    addFavoriteCar: (state, action) => {
      let isInArray = false;
      state.forEach((car) => {
        if (car.idn === action.payload.idn) {
          isInArray = true;
        }
      });
      if (!isInArray) {
        return [...state, action.payload];
      }
    },
    deleteFavoriteCar: (state, action) => {
      return state.filter((state) => state.idn != action.payload);
    },
  },
});
export const { addFavoriteCar, deleteFavoriteCar } = favoriteSlice.actions;
export const selectFavoriteCars = (state) => state.favoriteCars;

export default favoriteSlice.reducer;
