import { useSelector } from 'react-redux';
import { selectPartModel } from '../../../redux/slices/partModelSlices';
import { selectParts } from '../../../redux/slices/partsSlices';
import { useEffect } from 'react';
import { PartsContactBlock } from './PartsContactBlock';
import { PartsInfoBlock } from './PartsInfoBlock';
import { PartsBox } from '../../Home/Products/Parts/PartsBox';
import { ImHome } from 'react-icons/im';
import { Link } from 'react-router-dom';
import { BiCog } from 'react-icons/bi';
import { PartGallery } from './PartGallery';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const PartsModelPage = () => {
  const part = useSelector(selectPartModel);
  const parts = useSelector(selectParts);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [part]);

  const filterParts = parts.filter((item) => {
    return item.forModel === part.forModel && item.id !== part.id;
  });

  return part === false ? (
    <div className="loader h-[500px]"></div>
  ) : (
    <div>
      <ToastContainer />
      <div className="page-presentation-parts relative h-[150px]">
        <div className="container">
          <div className="links">
            <ul className="flex">
              <li className="link flex items-center">
                <ImHome className="mr-[5px]" />
                <Link to="/">Главная</Link>
              </li>
              <li className="link flex items-center">
                / <BiCog className="mr-[5px] ml-[10px]" />
                <Link to="/parts">Каталог запчастей</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="part-model">
          <PartGallery part={part} />
          <div className="part-content">
            <PartsInfoBlock part={part} />
            <PartsContactBlock part={part} />
          </div>
        </div>
        {filterParts != false ? (
          <div className="other-models">
            <div className="title">
              <h2>
                Другие запчасти для <span>{part.forModel}</span>
              </h2>
            </div>
            <PartsBox filterParts={filterParts} />
          </div>
        ) : (
          false
        )}
      </div>
    </div>
  );
};
