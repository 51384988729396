import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUsedCars } from '../../../../redux/slices/usedCarsSlices';

import { Filters } from './Filters';
import { CarsBox } from './CarsBox';

export const ModlesUsedCars = () => {
  const [modelCategory, setModelCategory] = useState('Все модели');
  const [carsCategory, setCarsCategory] = useState('Все марки');
  const [inHasCars, setInHasCars] = useState(false);
  const usedCars = useSelector(selectUsedCars);

  const filterModlesCar = (category) => {
    setModelCategory(category);
  };

  const filterCar = (category) => {
    setCarsCategory(category);
  };

  const toggleInHasCars = (e) => {
    setInHasCars(e.target.checked);
  };

  const filterCarsByMark =
    carsCategory !== 'Все марки'
      ? usedCars.filter((car) => {
          return car.category === carsCategory;
        })
      : usedCars;

  return (
    <div className="pt-[20px]">
      {usedCars === false ? (
        <div className="loader h-[500px]"></div>
      ) : (
        <>
          <Filters
            cars={usedCars}
            filterCar={filterCar}
            filterCarsByMark={filterCarsByMark}
            filterModlesCar={filterModlesCar}
            toggleInHasCars={toggleInHasCars}
          />
          <CarsBox
            filterCarsByMark={filterCarsByMark}
            modelCategory={modelCategory}
            inHasCars={inHasCars}
          />
        </>
      )}
    </div>
  );
};
