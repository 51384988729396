// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { RiPlayCircleLine, RiCalendarLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
// import required modules
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { useEffect, useState } from 'react';
import { BlogModal } from './BlogModal';
//
import { Button } from '../../UI/Button';
const blogInfo = [
  {
    title: 'Zeekr 007 - самый лучший Zeekr?',
    img: 'https://i.ytimg.com/vi/7yWjLo-miIU/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBlfRV4l48jnR_PCPW2TLGqHdb4IA',
    url: 'https://www.youtube.com/watch?v=7yWjLo-miIU&t=445s',
  },
  {
    title: 'ВЛОГ №3. Рабочая поездка в Китай',
    img: 'https://i.ytimg.com/vi/bcHdzKS89Ow/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAQURi1smITQIKBAkBXp5QzSRcOVQ',
    url: 'https://www.youtube.com/watch?v=bcHdzKS89Ow',
  },
  {
    title: 'BYD Leopard 5. Китайский LC Prado.',
    img: 'https://i.ytimg.com/vi/827leXWKI1w/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDTRP1nzF1Sj7OjPc8LK2i5dlznkw',
    url: 'https://www.youtube.com/watch?v=827leXWKI1w',
  },
  {
    title: 'BYD YANGWANG U8 - стоит ли брать?',
    img: 'https://i.ytimg.com/vi/K834HPGMezw/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLA77LHOvBU6fyBk8yxiqEM3VI0V-g',
    url: 'https://www.youtube.com/watch?v=K834HPGMezw&t=51s',
  },
  {
    title: 'Jetour Traveller. Убийца TANK 300.',
    img: 'https://i.ytimg.com/an_webp/_hEBU3heI88/mqdefault_6s.webp?du=3000&sqp=CMjVybYG&rs=AOn4CLB-SuQeKwO8J52WYm8SDQhffbntaQ',
    url: 'https://youtu.be/sdARtaGwbuw?si=S-Boeps7IHNMwjKI',
  },
  {
    title: 'Polar Stone 01 - убийца Lixiang L9!',
    img: 'https://i.ytimg.com/an_webp/_hEBU3heI88/mqdefault_6s.webp?du=3000&sqp=CMjVybYG&rs=AOn4CLB-SuQeKwO8J52WYm8SDQhffbntaQ',
    url: 'https://youtu.be/sdARtaGwbuw?si=S-Boeps7IHNMwjKI',
  },
];
export const BlogSection = () => {
  const [data, setData] = useState([]);
  const [blogModal, setBlogModal] = useState(false);
  const [video, setVideo] = useState('');
  useEffect(() => {
    try {
      fetch('/api/3')
        .then((res) => res.json())
        .then((res) => {
          setData(res);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  function toggleBlogModal(url) {
    setBlogModal(!blogModal);
    setVideo(url);
    !blogModal
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'visible');
  }
  return (
    <section className="Blog">
      <div className="container">
        <div className="blog-title">
          <div className="title">
            Ново<span>сти</span>
          </div>
        </div>

        <div className="blog-content mt-[40px]">
          {data == false ? (
            <div className="loader h-[303px]"></div>
          ) : (
            <Swiper
              slidesPerView={
                window.innerWidth <= 800
                  ? 1
                  : window.innerWidth <= 1250
                  ? 2
                  : window.innerWidth > 1250
                  ? 4
                  : 0
              }
              spaceBetween={30}
              grabCursor={true}
              loop={true}
              navigation={true}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              pagination={{
                dynamicBullets: true,
              }}
              modules={[Navigation, Pagination, Autoplay]}
              className="mySwiper"
            >
              {data
                .reverse()
                .slice(0, 5)
                .map((elem) => (
                  <SwiperSlide key={elem.id}>
                    <div className="blog-box">
                      <div className="img-blog-box">
                        <img
                          src={elem.img}
                          alt={elem.title}
                          className="h-[200px] w-[100%] rounded-[5px]"
                        />
                        <div className="img-font rounded-[5px]">
                          <RiPlayCircleLine
                            className="playIcon "
                            onClick={() => toggleBlogModal(elem.url)}
                          />
                        </div>
                      </div>
                      <div className="blog-date mt-[15px] flex justify-start items-center">
                        <RiCalendarLine className="blog-icon" />
                        <p className="ml-[10px] text-[16px] font-medium">
                          {elem.date}
                        </p>
                      </div>
                      <h3 className="mt-[10px]">{elem.title}</h3>
                    </div>
                  </SwiperSlide>
                ))}
              <div className="flex justify-center pt-[20px]">
                <Button url="news" title="Все новости" />
              </div>
            </Swiper>
          )}
        </div>
      </div>
      <BlogModal
        blogModal={blogModal}
        toggleBlogModal={toggleBlogModal}
        video={video}
      />
    </section>
  );
};
