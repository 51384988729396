import { useEffect, useState } from 'react';
import { AllCars } from './Cars/AllCars';
import { ModelsNewCars } from './Cars/ModelsNewCars';
import { ModlesUsedCars } from './Cars/ModlesUsedCars';
import { ImHome } from 'react-icons/im';
import { Link } from 'react-router-dom';
import { IoCarSportSharp } from 'react-icons/io5';
import { FormSection } from '../../UI/FormSection/FormSection';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const CarsPage = () => {
  const [tabIsActive, setTab] = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const tabActive = (n) => {
    setTab(n);
  };

  return (
    <div className="cars-page">
      <ToastContainer />
      <div className="page-presentation-cars relative">
        <div className="container">
          <div className="title text-center">
            <h1>Модели</h1>
          </div>
          <div className="links">
            <ul className="flex">
              <li className="link flex items-center">
                <ImHome className="mr-[5px]" />
                <Link to="/">Главная</Link>
              </li>
              <li className="flex items-center">
                / <IoCarSportSharp className="mr-[5px] ml-[10px]" />
                <span>Каталог автомобилей</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="cars-modes">
        <div className="container">
          <div className="tabs-filter">
            <div className="model-tabs">
              <div className="model-tabs-button">
                <button
                  className={tabIsActive === 1 ? 'active' : ''}
                  onClick={() => tabActive(1)}
                >
                  Все
                </button>
              </div>
              <div className="model-tabs-button">
                <button
                  className={tabIsActive === 2 ? 'active' : ''}
                  onClick={() => tabActive(2)}
                >
                  Новые
                </button>
              </div>
              <div className="model-tabs-button">
                <button
                  className={tabIsActive === 3 ? 'active' : ''}
                  onClick={() => tabActive(3)}
                >
                  С пробегом
                </button>
              </div>
            </div>
          </div>
          {tabIsActive === 1 ? (
            <div className="all-cars">
              <AllCars />
            </div>
          ) : tabIsActive === 2 ? (
            <div className="new-cars">
              <ModelsNewCars />
            </div>
          ) : (
            <div className="used-cars">
              <ModlesUsedCars />
            </div>
          )}
        </div>
        <FormSection title="Привезем любое авто " span="по лучшей цене" />
      </div>
    </div>
  );
};
