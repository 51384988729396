import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  toggleForm,
  selectToggleModal,
} from '../../../redux/slices/modalToggleSlices';
import { ImHome } from 'react-icons/im';
import { Link } from 'react-router-dom';
import { ImNewspaper } from 'react-icons/im';
// contac icon
import { FiPhone } from 'react-icons/fi';
import { GoMail } from 'react-icons/go';
import { FaRegClock } from 'react-icons/fa';
import { GoLocation } from 'react-icons/go';
import './ContactPage.css';
import { AboutSection } from '../../Home/AboutSection/AboutSection';
import revYandex from '../../../img/rev-1.webp';
import revGis from '../../../img/rev-2.webp';
import { FaStar, FaStarHalf } from 'react-icons/fa';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const ContactPage = () => {
  const dispatch = useDispatch();
  const toggleModal = useSelector(selectToggleModal);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const toggleFormModal = () => {
    dispatch(toggleForm());
    !toggleModal
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'visible');
  };

  return (
    <div className="conatacts">
      <ToastContainer />
      <div className="notfond-presentation relative">
        <div className="container">
          <div className="title text-center">
            <h1>Контакты</h1>
          </div>
          <div className="links">
            <ul className="flex">
              <li className="link flex items-center">
                <ImHome className="mr-[5px]" />
                <Link to="/">Главная</Link>
              </li>
              <li className="flex items-center">
                / <ImNewspaper className="mr-[5px] ml-[10px]" />
                <span>Контакты</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="contact-content">
          <div className="contact-inf">
            <ul>
              <li className="mt-[10px]">
                <div className="flex items-center">
                  <GoLocation className="mr-[10px] text-[#134791] text-[20px]" />
                  <strong>Адрес:</strong>
                </div>
                <span>ул. Седова, 24, корп. 1, Казань</span>
              </li>
              <li className="mt-[10px]">
                <div className="flex items-center">
                  <FaRegClock className="mr-[10px] text-[#134791] text-[20px]" />
                  <strong>Режим работы:</strong>
                </div>
                <span>Ежедневно с 10:00 - 20:00</span>
              </li>
              <li className="mt-[10px]">
                <div className="flex items-center">
                  <FiPhone className="mr-[10px] text-[#134791] text-[20px]" />
                  <strong>Номер телефона:</strong>
                </div>
                <a href="tel:+78047004041">+7(804)700-40-41</a>
              </li>
              <li className="mt-[10px]">
                <div className="flex items-center">
                  <GoMail className="mr-[10px] text-[#134791] text-[20px]" />
                  <strong>Электронная почта:</strong>
                </div>
                <a href="mailto:neofical@nefical.ru">neofical@nefical.ru</a>
              </li>
            </ul>
            <button onClick={() => toggleFormModal()} className="contac-btn">
              Заказать звонок
            </button>
          </div>
          <iframe
            className="map"
            src="https://yandex.ru/map-widget/v1/?um=constructor%3A314960f5317efc4d1136d1d70dd553b0c1bb6ec947e847e7d1581cc290babd83&amp;source=constructor"
            width="500"
            height="400"
            frameborder="0"
          ></iframe>
        </div>
        <div className="revies mt-[40px]">
          <div className="revies-box">
            <div className="right-rev-content">
              <h3>Яндекс Карты</h3>
              <div className="flex items-center mt-[10px] mb-[10px]">
                <span>4.8</span>
                <div className="star-box">
                  <FaStar className="icon checked" />
                  <FaStar className="icon checked" />
                  <FaStar className="icon checked" />
                  <FaStar className="icon checked" />
                  <FaStarHalf className="icon checked" />
                </div>
              </div>
              <a
                href="https://yandex.tj/maps/org/neofitsal_avto/110534909234/reviews/?ll=49.180748%2C55.788152&z=18"
                target="_blank"
              >
                Отзывы
              </a>
            </div>
            <div className="left-rev-content">
              <img src={revYandex} alt="neofical" />
            </div>
          </div>
          <div className="revies-box">
            <div className="right-rev-content">
              <h3>2ГИС</h3>
              <div className="flex items-center mt-[10px] mb-[10px]">
                <span>5</span>
                <div className="star-box">
                  <FaStar className="icon checked" />
                  <FaStar className="icon checked" />
                  <FaStar className="icon checked" />
                  <FaStar className="icon checked" />
                  <FaStar className="icon checked" />
                </div>
              </div>
              <a
                href="https://2gis.ru/kazan/inside/2956122910680867/firm/70000001078770744/tab/reviews?m=49.180412%2C55.787974%2F20"
                target="_blank"
              >
                Отзывы
              </a>
            </div>
            <div className="left-rev-content">
              <img src={revGis} alt="neofical" />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-[40px]">
        <AboutSection />
      </div>
    </div>
  );
};
