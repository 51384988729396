import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const partModelSlices = createSlice({
  name: 'partModel',
  initialState: initialState,
  reducers: {
    addPart: (state, action) => {
      return (state = action.payload);
    },
  },
});

export const { addPart } = partModelSlices.actions;
export const selectPartModel = (state) => state.partModel;

export default partModelSlices.reducer;
