import { Swiper, SwiperSlide } from 'swiper/react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import 'swiper/css';
import 'swiper/css/scrollbar';
import { Autoplay, Scrollbar } from 'swiper/modules';
import { Link } from 'react-router-dom';

export const Header = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await axios.get('./api/6');
        setData(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);

  return (
    <header>
      <Swiper
        scrollbar={{
          hide: false,
        }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        loop={true}
        grabCursor={true}
        modules={[Scrollbar, Autoplay]}
        className="mySwiper"
      >
        {data.map((item) => (
          <SwiperSlide key={item.id}>
            <div className={`presentation-${item.id}`}>
              <div className="container">
                <div className="presentation-content">
                  <h1>
                    {item.presentTitle}
                    <br />
                    <span className="title-span">{item.presentDis}</span>
                  </h1>

                  <Link
                    to={item.id === 3 ? 'parts' : 'models'}
                    className="button"
                  >
                    <span className="btn-content">
                      <span className="btn-arrow">&#10230;</span>
                      <span className="btn-text">Подробнее</span>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </header>
  );
};
