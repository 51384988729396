import { FaArrowRightArrowLeft } from 'react-icons/fa6';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectFavoriteCars } from '../../../../redux/slices/favoriteSlices';
import { selectToggleModal } from '../../../../redux/slices/modalToggleSlices';
import { toggleForm } from '../../../../redux/slices/modalToggleSlices';
// import { Favorite } from './Favorite/Favorite';
import { FiSearch } from 'react-icons/fi';
import { Comparison } from './Comparison/Comparison';
import { Search } from './Search/Search';

export const MenuIcons = () => {
  const dispatch = useDispatch();
  const favoriteCars = useSelector(selectFavoriteCars);
  const toggleModal = useSelector(selectToggleModal);

  const count = favoriteCars.length;
  const [favoriteModal, setFavoriteModal] = useState(false);
  const [comparisonModal, setComparisonModal] = useState(false);

  const toggleFormModal = () => {
    dispatch(toggleForm());
    !toggleModal
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'visible');
  };

  const toggleFavoriteModal = () => {
    setFavoriteModal(!favoriteModal);
    !favoriteModal
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'visible');
  };
  const toggleComparisonModal = () => {
    setComparisonModal(!comparisonModal);
    !comparisonModal
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'visible');
  };
  return (
    <>
      <ul className="flex items-center">
        {/* <li className="favorite-icon" onClick={() => toggleFavoriteModal()}>
          <FaRegBookmark className="nav-icons" />
          {conut > 0 ? <span>{conut}</span> : false}
        </li>
        <Favorite
          favoriteModal={favoriteModal}
          toggleFavoriteModal={toggleFavoriteModal}
        /> */}
        <li className="favorite-icon" onClick={() => toggleFavoriteModal()}>
          <FiSearch className="nav-icons " />
        </li>
        <Search
          favoriteModal={favoriteModal}
          toggleFavoriteModal={toggleFavoriteModal}
        />
        <li className="comparison-icon" onClick={() => toggleComparisonModal()}>
          <FaArrowRightArrowLeft className="nav-icons" />
          {count > 0 ? <span>{count}</span> : false}
        </li>
        <li className="nav-phone p-[8px] ml-[30px] flex items-center">
          <a
            href="tel:+78047004041"
            className="text-white font-bold text-[12px]"
          >
            +7(804)700-40-41
          </a>
        </li>
        <li
          className="nav-phone p-[8px] ml-[10px] flex items-center cursor-pointer"
          onClick={toggleFormModal}
        >
          <span className="text-white font-bold  text-[12px]">
            Обратный звонок
          </span>
        </li>

        <Comparison
          comparisonModal={comparisonModal}
          toggleComparisonModal={toggleComparisonModal}
        />
      </ul>
    </>
  );
};
