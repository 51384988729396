import { useState } from 'react';
import { useSelector } from 'react-redux';
import { PartsBox } from './PartsBox';
import { selectParts } from '../../../../redux/slices/partsSlices';
import { Button } from '../../../UI/Button';
import { FilterPars } from './FilterPars';

export const Parts = () => {
  const parts = useSelector(selectParts);
  const [partsCategory, setPartsCategory] = useState('Все запчасти');

  const setfilterParts = (category) => {
    setPartsCategory(category);
  };

  const filterParts =
    partsCategory !== 'Все запчасти'
      ? parts.filter((part) => {
          return part.forModel === partsCategory;
        })
      : parts;

  return (
    <div className="parts">
      {parts == false ? (
        <div className="loader h-[303px]"></div>
      ) : (
        <div className="parts-content">
          <FilterPars parts={parts} setfilterParts={setfilterParts} />
          <PartsBox filterParts={filterParts} />
          <div className="flex justify-center pt-[20px]">
            <Button url="parts" title="Все товары" />
          </div>
        </div>
      )}
    </div>
  );
};
