import { useState, useEffect } from 'react';
import logo from '../../../img/logo.png';

import { Link, NavLink } from 'react-router-dom';
import { MenuIcons } from './menu-icons/MenuIcons';

export const Navigation = () => {
  const [sticky, setSticky] = useState('');
  const [menuIsOpen, setMenu] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  }, []);
  const isSticky = () => {
    if (window.innerWidth >= 1100) {
      const stickyClass = window.scrollY >= 20 ? 'is-sticky' : '';
      setSticky(stickyClass);
    } else {
      const stickyClass = window.scrollY >= 10 ? 'is-sticky' : '';
      setSticky(stickyClass);
    }
  };

  const toggleMenu = () => {
    if (window.innerWidth <= 1100) {
      setMenu(!menuIsOpen);

      !menuIsOpen
        ? (document.body.style.overflow = 'hidden')
        : (document.body.style.overflow = 'visible');
    }
  };
  const closeMenu = () => {
    setMenu(false);
    document.body.style.overflow = 'visible';
  };
  return (
    <nav className={`fixed  w-[100%] ${sticky}`}>
      <div className="container">
        <div className="nav-content">
          <div className="logo">
            <Link to="/" onClick={() => closeMenu()}>
              <img src={logo} alt="Неофицал авто" />
            </Link>
          </div>
          <div className="menu">
            <ul className="menu-links">
              <li>
                <NavLink to="models">Модели авто</NavLink>
              </li>
              <li>
                <NavLink to="parts">Запчасти</NavLink>
              </li>
              {/* <li>
                <NavLink to="trade-in">Trade-in</NavLink>
              </li> */}
              <li>
                <NavLink to="news">Новости</NavLink>
              </li>
              <li>
                <NavLink to="contact">Контакты</NavLink>
              </li>
            </ul>
          </div>
          <div className="menu-icons">
            <MenuIcons />
            <div
              className={menuIsOpen ? 'humburger-menu open' : 'humburger-menu'}
              onClick={() => toggleMenu()}
            >
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
            </div>
          </div>
          <div className={menuIsOpen ? 'mobile-menu active' : 'mobile-menu'}>
            <div className="mob-menu">
              <ul className="mob-menu-links">
                <li>
                  <NavLink
                    to="models"
                    className="active"
                    onClick={() => toggleMenu()}
                  >
                    Модели авто
                  </NavLink>
                </li>
                <li>
                  <NavLink to="parts" onClick={() => toggleMenu()}>
                    Запчасти
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink to="trade-in" onClick={() => toggleMenu()}>
                    Trade-in
                  </NavLink>
                </li> */}
                <li>
                  <NavLink to="news" onClick={() => toggleMenu()}>
                    Новости
                  </NavLink>
                </li>
                <li>
                  <NavLink to="contact" onClick={() => toggleMenu()}>
                    Контакты
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};
