import axios from 'axios';
import { useState } from 'react';
import { FaXmark } from 'react-icons/fa6';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './modal.css';
import { Link } from 'react-router-dom';
import { useMask } from '@react-input/mask';

export const Modal = ({ toggleModal, toggleFormModal }) => {
  const [buttonLoanding, setButtonLoanding] = useState(false);
  const [values, setValues] = useState({
    name: '',
    phone: '',
    message: '',
    policy: false,
  });

  const notify = () =>
    toast.success('Заявка успешно отправлена', {
      autoClose: 2000,
      position: 'top-left',
    });

  const notifyError = () =>
    toast.error('Сообщение не отправлено. Повторите попытку', {
      autoClose: 2000,
      position: 'top-left',
    });

  const phoneRef = useMask({
    mask: '+7 (___) ___-__-__',
    replacement: { _: /\d/ },
  });

  const getInputData = (event, name) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    const TOKEN = '7968206331:AAE3e3ObibGeYLKj63RjjP8Abbh9Vvor5dk';
    const CHAT_ID = '-1002379583102';
    const URL_API = `https://api.telegram.org/bot${TOKEN}/sendMessage`;
    let message = `<b>Имя:</b> ${values.name}\n`;
    message += `<b>Телефон:</b> ${values.phone}\n`;
    message += `<b>Сообщение:</b> ${values.message}\n`;

    setButtonLoanding(!buttonLoanding);

    try {
      await axios.post(URL_API, {
        chat_id: CHAT_ID,
        parse_mode: 'html',
        text: message,
      });
      notify();
      setValues({ name: '', phone: '', message: '', policy: false });
      setButtonLoanding(false);
      toggleFormModal();
    } catch (error) {
      setButtonLoanding(false);
      notifyError();
    }
  };

  // const onSubmit = async (event) => {
  //   event.preventDefault();

  //   const formData = new FormData(event.target);

  //   formData.append('access_key', '709e8e02-f010-4466-bf0f-6090d7007b81');

  //   const object = Object.fromEntries(formData);
  //   const json = JSON.stringify(object);

  //   const res = await fetch('https://api.web3forms.com/submit', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Accept: 'application/json',
  //     },
  //     body: json,
  //   }).then((res) => res.json());

  //   if (res.success) {
  //     Swal.fire({
  //       position: 'center',
  //       icon: 'success',
  //       title: 'Заявка успешно отправлена',
  //       showConfirmButton: false,
  //       timer: 1500,
  //     });
  //     setValues({ name: '', phone: '', massage: '' });
  //     toggleFormModal();
  //   }
  // };

  return (
    <div className="form-modal">
      <div
        className={toggleModal ? 'modal-window close-modal' : 'modal-window'}
      >
        <div className="modal">
          <div className="modal-content">
            <div className="modal-nav">
              <div className="modal-title">
                <h2>Заявка на обратный звонок</h2>
                <p className="text-[14px] font-medium">
                  Наш менеджер перезвонит вам
                </p>
              </div>
              <div className="modal-icon-close">
                <FaXmark
                  className="close-modal-icon"
                  onClick={() => toggleFormModal()}
                />
              </div>
            </div>
            <div className="form-content">
              <form onSubmit={onSubmit}>
                <div className="input-box">
                  <label>Как к вам обращаться?</label>
                  <input
                    className="field"
                    value={values.name}
                    type="text"
                    name="Имя"
                    placeholder="Имя"
                    minLength="3"
                    required
                    onChange={(e) => getInputData(e, 'name')}
                  />
                </div>
                <div className="input-box">
                  <label>Телефон</label>
                  <input
                    className="field"
                    type="tel"
                    name="Телефон"
                    value={values.phone}
                    ref={phoneRef}
                    onChange={(e) => getInputData(e, 'phone')}
                    placeholder="+7(___)___-__-__"
                    required
                  />
                </div>
                <div className="input-box">
                  <label>Что вас интересует?</label>
                  <textarea
                    className="field mess"
                    name="Сообщения"
                    placeholder="Сообщения"
                    value={values.message}
                    onChange={(e) => getInputData(e, 'message')}
                  ></textarea>
                </div>
                <p>
                  <input
                    type="checkbox"
                    required
                    value={values.policy}
                    onChange={(e) =>
                      setValues({ ...values, policy: e.target.checked })
                    }
                    name="Согласен на обработку персональных данных"
                  />
                  <span> Согласен на обработку </span>
                  <Link
                    to="privacy"
                    onClick={toggleFormModal}
                    className="decoration-solid decoration-slice"
                  >
                    персональных данных
                  </Link>
                </p>
                <button type="submit">
                  {!buttonLoanding ? (
                    'Жду звонка'
                  ) : (
                    <div className="loader-2 h-[20px]"></div>
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
