import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectNewCars } from '../../../../redux/slices/newCarsSlisec';

import { Filters } from './Filters';
import { CarsBox } from './CarsBox';

export const ModelsNewCars = () => {
  const [modelCategory, setModelCategory] = useState('Все модели');
  const [carsCategory, setCarsCategory] = useState('Все марки');
  const [inHasCars, setInHasCars] = useState(false);
  const newCars = useSelector(selectNewCars);

  const filterModlesCar = (category) => {
    setModelCategory(category);
  };

  const filterCar = (category) => {
    setCarsCategory(category);
  };

  const toggleInHasCars = (e) => {
    setInHasCars(e.target.checked);
  };

  const filterCarsByMark =
    carsCategory !== 'Все марки'
      ? newCars.filter((car) => {
          return car.category === carsCategory;
        })
      : newCars;

  return (
    <div className="pt-[20px]">
      {newCars === false ? (
        <div className="loader h-[500px]"></div>
      ) : (
        <>
          <Filters
            cars={newCars}
            filterCar={filterCar}
            filterCarsByMark={filterCarsByMark}
            filterModlesCar={filterModlesCar}
            toggleInHasCars={toggleInHasCars}
          />
          <CarsBox
            filterCarsByMark={filterCarsByMark}
            modelCategory={modelCategory}
            inHasCars={inHasCars}
          />
        </>
      )}
    </div>
  );
};
