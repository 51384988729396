import { Link, NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  toggleForm,
  selectToggleModal,
} from '../../../redux/slices/modalToggleSlices';
import {
  FaYoutube,
  FaWhatsapp,
  FaInstagram,
  FaTelegram,
} from 'react-icons/fa6';
// contac icon
import { FiPhone } from 'react-icons/fi';
import { FaRegClock } from 'react-icons/fa';
import { GoLocation } from 'react-icons/go';
import { GrMapLocation } from 'react-icons/gr';
import { GoMail } from 'react-icons/go';
import { VscCallOutgoing } from 'react-icons/vsc';
import { BiSolidMessageDetail } from 'react-icons/bi';
import { PiTelegramLogo } from 'react-icons/pi';
import { IoClose } from 'react-icons/io5';
import { useState } from 'react';
import { Modal } from '../../UI/Modal';

export const Footer = () => {
  const dispatch = useDispatch();
  const toggleModal = useSelector(selectToggleModal);
  const [elemClose, setElemClose] = useState(false);

  const toggleFormModal = () => {
    dispatch(toggleForm());
    !toggleModal
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'visible');
  };

  const handelElem = () => {
    setElemClose(!elemClose);
  };

  return (
    <footer>
      <div className="container">
        {/* /// */}
        <div className="conatacts-box">
          <div
            className={!elemClose ? 'contact-elemts' : 'contact-elemts active'}
          >
            <div className="overflow-hidden">
              <ul>
                <li>
                  <a href="tel:+78047004041">
                    <VscCallOutgoing className="icon" />
                  </a>
                </li>
                <li onClick={toggleFormModal}>
                  <GoMail className="icon cursor-pointer" />
                </li>
                <li>
                  <a href="https://wa.me/+79991570945?" target="_blank">
                    <FaWhatsapp className="icon" />
                  </a>
                </li>
                <li>
                  <a href="https://t.me/Neofical_auto" target="_blank">
                    <PiTelegramLogo className="icon" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://instagram.com/neofical_auto?igshid=MjEwN2IyYWYwYw=="
                    target="_blank"
                  >
                    <FaInstagram className="icon" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://yandex.ru/maps/org/neofitsal_avto/110534909234/?ll=49.180748%2C55.788152&z=18"
                    target="_blank"
                  >
                    <GrMapLocation className="icon" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="contacts-activator">
            <button className="cursor-pointer" onClick={handelElem}>
              {!elemClose ? (
                <BiSolidMessageDetail className="icon" />
              ) : (
                <IoClose className="icon" />
              )}
            </button>
          </div>
        </div>
        {/* // */}
        <div className="footer-content">
          <div className="footer-about-us">
            <h3>О нас</h3>
            <p>
              Неофицал авто - компания по <br /> импорту автомобилей из Китая.
            </p>
          </div>
          <div className="footer-contacts">
            <h3>Контакты</h3>
            <ul>
              <li>
                <FiPhone className="icon-bar" />
                <a href="tel:+78047004041">+7(804)700-40-41</a>
              </li>
              <li onClick={toggleFormModal}>
                <GoMail className="icon-bar" />
                <span>Обратная связь</span>
              </li>
              <li>
                <FaRegClock className="icon-bar" />
                <span>Пн - Вс 10:00 - 20:00</span>
              </li>
              <li>
                <GoLocation className="icon-bar" />
                <a
                  href="https://yandex.ru/maps/org/neofitsal_avto/110534909234/?ll=49.180748%2C55.788152&z=18"
                  target="_blank"
                >
                  г. Казань. ул. Седова 24к1
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-nav">
            <h3>Навигатор</h3>
            <ul>
              <li>
                <NavLink to="models">Модели авто</NavLink>
              </li>
              <li>
                <NavLink to="parts">Запчасти</NavLink>
              </li>
              <li>
                <NavLink to="news">Новости</NavLink>
              </li>
              <li>
                <NavLink to="contact">Контакты</NavLink>
              </li>
            </ul>
          </div>
          <div className="footer-contact-sites">
            <h3>Мы в соцсетях</h3>
            <div className="footer-sots flex">
              <a
                className="text-[#ff0000]"
                href="https://youtube.com/@neofical_auto"
                target="_blank"
              >
                <FaYoutube />
              </a>
              <a
                style={{ color: '#128C7E' }}
                href="https://wa.me/+79991570945?"
                target="_blank"
              >
                <FaWhatsapp />
              </a>
              <a
                style={{ color: '#E1306C' }}
                href="https://instagram.com/neofical_auto?igshid=MjEwN2IyYWYwYw=="
                target="_blank"
              >
                <FaInstagram />
              </a>
              <a
                style={{ color: '#1DA1F2' }}
                href="https://t.me/Neofical_auto"
                target="_blank"
              >
                <FaTelegram />
              </a>
            </div>
          </div>
        </div>
        <div className="footer-wysiwg">
          <p>
            «Неофицал авто» (г. Казань) ведет деятельность на территории РФ в
            соответствии с законодательством РФ. Технические характеристики и
            оборудование автомобилей, условия приобретения автомобилей, цены,
            спецпредложения и комплектации автомобилей, указанные на сайте,
            приведены для примера и могут быть изменены в любое время без
            предварительного уведомления.
          </p>
        </div>
        <div className="footer-copyright">
          <p>Ⓒ Copyright 2024. Все права защищены.</p>
          <Link to="privacy">Cooke Защита данных и условия использование</Link>
        </div>
      </div>
      <Modal toggleModal={toggleModal} toggleFormModal={toggleFormModal} />
    </footer>
  );
};
