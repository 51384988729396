import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { addNewCars } from './redux/slices/newCarsSlisec';
import { addUsedCars } from './redux/slices/usedCarsSlices';
import { addParts } from './redux/slices/partsSlices';
import { useEffect } from 'react';
import { Main } from './components/Home/Main';
import { Route, Routes } from 'react-router-dom';
import { Layout } from './components/layout/Layout';
import { NotFound } from './components/404/NotFound';
import { News } from './components/Pages/News/News';
import { CarsPage } from './components/Pages/CasrsPage/CarsPage';
import { PartsPage } from './components/Pages/PartsPage/PartsPage';
import { CarsModelPage } from './components/Pages/CarsModelPage/CarsModelPage';
import { PartsModelPage } from './components/Pages/PartsModelPage/PartsModelPage';
import { Privacy } from './components/Privasy/Privacy';
import { ContactPage } from './components/Pages/Contacts/ContactPage';

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    const getNewCars = async () => {
      try {
        const resNewCars = await axios.get('/api/1');
        const resUsedCars = await axios.get('/api/5');
        const resParts = await axios.get('/api/2');

        const carsData = resNewCars.data;
        const usedCarsData = resUsedCars.data;
        const partData = resParts.data;

        dispatch(
          addNewCars(
            carsData.map((car) => {
              return { ...car, idn: uuidv4() };
            })
          )
        );
        dispatch(
          addUsedCars(
            usedCarsData.map((car) => {
              return { ...car, idn: uuidv4() };
            })
          )
        );
        dispatch(addParts(partData));
      } catch (error) {
        console.log(error);
      }
    };
    getNewCars();
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Main />} />
          <Route path="models" element={<CarsPage />} />
          <Route path="models/:carsName" element={<CarsModelPage />} />
          <Route path="parts" element={<PartsPage />} />
          <Route path="parts/:parsName" element={<PartsModelPage />} />

          <Route path="news" element={<News />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
