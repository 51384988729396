import { FaXmark } from 'react-icons/fa6';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectFavoriteCars,
  deleteFavoriteCar,
} from '../../../../../redux/slices/favoriteSlices';
import { Button } from '../../../../UI/Button';
import { CompBox } from './CompBox';

export const Comparison = ({ comparisonModal, toggleComparisonModal }) => {
  const cars = useSelector(selectFavoriteCars);
  const dispatch = useDispatch();

  const deleteCar = (id) => {
    dispatch(deleteFavoriteCar(id));
  };

  return (
    <div
      className={comparisonModal ? 'modal-window close-modal' : 'modal-window'}
    >
      <div className="modal">
        <div className="modal-nav">
          <div className="modal-title">
            <h2>Сравнение</h2>
          </div>
          <div className="modal-icon-close">
            <FaXmark
              className="close-modal-icon"
              onClick={() => toggleComparisonModal()}
            />
          </div>
        </div>
        <div className="modal-content">
          <div className="modal-container w-[95%] m-auto min-h-[85vh]">
            <div className="comparicom-content">
              {cars.length === 0 ? (
                <div className="w-[100%] flex justify-center mt-[100px] text-center">
                  <div className="w-[100%]">
                    <h3>
                      <b>Не добавлено автомобилей для сравнения</b>
                    </h3>
                    <p>
                      Из модельного ряда можно добавить автомобили к сравнению
                    </p>
                    <div
                      className="flex justify-center"
                      onClick={() => toggleComparisonModal()}
                    >
                      <Button url="models" title="Модельный ряд" />
                    </div>
                  </div>
                </div>
              ) : (
                <CompBox cars={cars} deleteCar={deleteCar} />
              )}
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};
