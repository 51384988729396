import { FaXmark } from 'react-icons/fa6';
export const BlogModal = ({ blogModal, toggleBlogModal, video }) => {
  return (
    <div
      className={
        blogModal
          ? 'modal-window close-modal fixed cursor-pointer'
          : 'modal-window'
      }
    >
      <div className="blog-modal-content" onClick={() => toggleBlogModal()}>
        <div className="blog-video">
          <div className="blog-modal-close-icon">
            <FaXmark
              className="close-modal-icon"
              onClick={() => toggleBlogModal()}
            />
          </div>
          {!video ? (
            <div className="loader h-[303px]"></div>
          ) : (
            <iframe
              className="youtube-video"
              src={video}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          )}
        </div>
      </div>
    </div>
  );
};
