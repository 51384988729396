import { createSlice } from '@reduxjs/toolkit';

const initialState = 0;

const countCarsSlices = createSlice({
  name: 'countCars',
  initialState: initialState,
  reducers: {
    addCarsCount: (state, action) => {
      return (state = action.payload);
    },
  },
});

export const { addCarsCount } = countCarsSlices.actions;
export const selectCountCars = (state) => state.countCars;

export default countCarsSlices.reducer;
