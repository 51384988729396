import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import './Privacy.css';
import { ImHome } from 'react-icons/im';

export const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="Privac">
      <div className="privac-presentation">
        <div className="container">
          <div className="title">
            <h1>Политика конфиденциальности и защиты личной информации</h1>
          </div>
          <div className="links">
            <ul className="flex">
              <li className="link flex items-center">
                <ImHome className="mr-[5px]" />
                <Link to="/">Главная</Link>
              </li>
              <li>/ Политика конфиденциальности</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="privacy-content">
          <h2>Согласие на обработку персональных данных</h2>
          <p>
            Настоящим, в целях исполнения положений ФЗ РФ №152-ФЗ от 27.07.2006
            года «О персональных данных», даю свое согласие на обработку ,
            Неофицал авто г. Казани, ул. Седова, д. 24к1), именуемому в
            дальнейшем «Оператор», в том числе юридическим лицам, аффилированным
            с Оператором, зависимым и дочерним организациям Оператора, основным
            организациям по отношению к Оператору, компаниям, входящим в группу
            компаний Неофицал авто и указанным  на сайте https//neofical.ru
            (актуальный список доступен по ссылке , а также другим лицам,
            которым Оператор имеет право поручить обработку персональных моих
            данных (ФИО; дата, год, месяц рождения; пол; паспортные данные;
            адрес местожительства; номер телефона и адрес электронной почты;
            информация об автомобиле и др.) и предоставляю право совершать все
            действия (операции) с моими персональными данными, включая сбор,
            систематизацию, накопление, хранение, уточнение (обновление,
            изменение), извлечение, использование, распространение (в том числе
            передачу), обезличивание, блокирование, удаление, уничтожение.{' '}
            <br /> <br /> Цель: учет предоставленной информации в базах данных;
            проведения статистических исследований, а также исследований,
            направленных на улучшение качества продукции и услуг; оказания услуг
            помощи на дорогах; осуществления процедуры отзыва автомобилей для
            устранения дефектов; проведения маркетинговых программ, в том числе,
            для продвижения товаров, работ, услуг на рынке; информирование о
            новых товарах и услугах с помощью различных средств связи, а именно
            посредством: интернет; почтовых рассылок; сообщений на адрес
            электронной почты; коротких текстовых сообщений (SMS) и
            мультимедийных сообщений (MMS) на номер телефона; а также
            посредством использования информационно-коммуникационных сервисов,
            таких как Viber, WhatsUp и тому подобных; телефонных звонков.
            Согласие распространяется на информацию, вводимую в полях
            регистрации данного сайта ГК. Давая данное согласие я подтверждаю
            свои полномочия на представление указанных персональных данных и
            обязуюсь возместить любые расходы, связанные с отсутствием у меня
            соответствующих полномочий, в том числе убытки, связанные с
            санкциями проверяющих органов. <br /> <br /> Настоящее согласие на
            обработку моих персональных данных действует до момента его отзыва в
            соответствии со статьей 9 Федерального закона от 27.07.2006 года
            №152-ФЗ «О персональных данных» посредством направления
            соответствующего заявления, направленного на адрес электронной почты
            Оператора neoficalll@gmail.ru или посредством предоставлении
            письменного заявления по адресу Оператора, указанного в настоящем
            Согласии. Оператор, партнеры Оператора, а также Группа Компаний
            «Неофицал авто» («Neofical Avto») осуществляет (осуществляют) свою
            деятельность на территории РФ в соответствии с законодательством РФ.
            Предлагаемые товары/работы/услуги доступны к получению исключительно
            на территории РФ. Мониторинг потребительского поведения субъектов,
            находящихся за пределами РФ, не ведется.
          </p>
        </div>
        <div className="privacy-content">
          <h2>Политика конфиденциальности</h2>
          <p>
            Веб-сайт https://neofical.ru (далее – мы) заботится о сохранении
            конфиденциальности Вашей информации. В настоящем документе
            описываются принятые https://neofical.ru правила использования
            персональных данных, которые вы сообщаете нам в онлайне или в
            офлайне — по обычной почте, в конкурсных материалах, по телефону, в
            ходе опросов, используя возможности сайта и т.п. Внимательно
            прочтите весь документ. Продолжая пользоваться веб-сайтом
            https://neofical.ru или предоставляя нам свою личную информацию
            через веб-сайт или офлайновыми методами, Вы соглашаетесь с этой
            Политикой конфиденциальности.
            <br />
            <br /> Время от времени https://neofical.ru может пересматривать
            Политику конфиденциальности, отражая изменения в законодательстве,
            мерах соблюдения конфиденциальности https://neofical.ru или
            содержании веб-сайта. Если такие изменения влияют на то, как мы
            собираем и используем Вашу информацию, эти изменения будут внесены в
            настоящую Политику конфиденциальности, и будет объявлена дата их
            вступления в силу. Поэтому, чтобы оставаться в курсе текущей
            политики, необходимо периодически перечитывать Политику
            конфиденциальности. Даже если https://neofical.ru существенно
            изменит свои методы сбора и использования персональных данных, Ваша
            личная информация будет по-прежнему регулироваться той Политикой, по
            которой она была собрана. <br />
            <br />
            Используемые в настоящей Политике конфиденциальности термины «мы» и
            «нас» относятся к https://neofical.ru
          </p>
        </div>
        <div className="privacy-content">
          <h2>Обработка персональных данных</h2>
          <p>
            Персональные данные сохраняются лишь при условии вашего согласия на
            их предоставление нам, например, при регистрации на сайте,
            проведении опросов, конкурсов или при наличии иных законных
            оснований для обработки данных, например, в целях заключения и
            исполнения договоров. <br /> <br /> https://neofical.ru использует
            Ваши персональные данные для создания и управления клиентской базой,
            проведения опросов по продукции компании и в маркетинговых целях, в
            частности, для предоставления возможности взаимодействовать с
            Сайтом, продвижения товаров и услуг, получения и исследования
            статистических данных об объемах продаж и качестве услуг,
            оказываемых https://neofical.ru Передача персональных данных
            государственным органам осуществляется исключительно в рамках
            обязывающих нас требований законодательства. На наших сотрудников и
            контрагентов распространяются обязательства о строгом соблюдении
            конфиденциальности.
          </p>
        </div>
        {/* <div className="privacy-content">
          <h2>Право выбора</h2>
          <p>
            Мы хотели бы использовать Ваши данные, чтобы иметь возможность
            информировать Вас о нашей продукции и предоставляемых нами услугах
            и, при необходимости, узнавать Ваше мнение о них с вашего
            предварительного согласия. Если Вы не согласны далее участвовать в
            них, можете в любое время сообщить нам об этом, чтобы Ваши данные не
            использовались для данной цели. Дальнейшую информацию Вы найдете на
            соответствующих страницах нашего сайта. <br /> <br /> На нашем
            веб-сайте может быть предоставлена возможность подписки на
            электронные рассылки новостей. Вы можете в любое время отказаться от
            указанной рассылки, воспользовавшись для этого предусмотренной в
            рассылке опцией. <br /> <br /> По вашему запросу мы предоставим Вам
            обзор Вашей персональных данных, которую Вы предоставили
            https://neofical.ru и примем разумные меры для исключения любой
            некорректной персональных данных, о которой Вы нам сообщите. Если Вы
            попросите нас прекратить использование персональных данных,
            предоставленной Вами https://neofical.ru мы удовлетворим эту
            просьбу, сохранив все записи Вашей персональных данных, необходимые
            для соблюдения действующих федеральных законов, законов штата или
            местных законов. <br /> <br /> При Вашем обращении или обращении
            Вашего законного представителя, запросе в письменной или электронной
            форме, на доступ к своим персональным данным https://neofical.ru
            руководствуется требованиями статей 14, 18 и 20 Федерального закона
            № 152-ФЗ. <br /> <br /> В зависимости от информации, предоставленной
            в запросе, https://neofical.ru принимает решение о предоставлении
            Вам доступа к Вашим персональным данным. В случае если данных
            предоставленных Вами недостаточно для установления Вашей личности
            или предоставление персональных данных нарушает конституционные
            права и свободы других лиц https://neofical.ru подготавливает
            мотивированный ответ, содержащий ссылку на положение части 8 статьи
            14 Федерального Закона № 152-ФЗ или иного Федерального Закона,
            являющееся основанием для такого отказа, в срок, не превышающий
            тридцати рабочих дней от даты получения Вашего обращения или
            обращения Вашего законного представителя. <br /> <br /> Сведения о
            наличии персональных данных предоставляются Вам при ответе на запрос
            в течение тридцати дней от даты получения Вашего запроса или запроса
            Вашего законного представителя. <br /> <br /> Веб-сайт
            https://neofical.ru оставляет за собой право проверить полноту и
            точность предоставляемой персональных данных. В случае выявления
            ошибочной или неполной информации, Оператор имеет право прекратить
            все отношения с Вами. <br /> <br /> Если вы хотите связаться с нами
            по поводу нашей Политики конфиденциальности или по поводу обработки
            https://neofical.ru Ваших персональных данных, проверить или
            исправить свою личную информацию или отказаться от любых наших
            будущих сообщений, а также отозвать согласие на обработку
            персональных данных, пожалуйста, обращайтесь по форме обратной связи
            на странице контактов текущего веб-сайта. <br /> <br /> По Вашему
            запросу https://neofical.ru в соответствии с действующим
            законодательством ответит Вам, хранятся ли у нас Ваши персональные
            данные и если да, то какие. Если при всем нашем стремлении к
            достоверности и актуальности хранимой у нас информации она по
            каким-либо причинам окажется неточной, то по Вашему требованию в нее
            будут внесены соответствующие исправления.
          </p>
        </div> */}
      </div>
    </div>
  );
};
