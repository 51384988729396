import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const newCarsSlices = createSlice({
  name: 'newCars',
  initialState: initialState,
  reducers: {
    addNewCars: (state, action) => {
      return (state = action.payload);
    },
  },
});

export const { addNewCars } = newCarsSlices.actions;
export const selectNewCars = (state) => state.newCars;

export default newCarsSlices.reducer;
