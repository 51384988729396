// reducer
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { addFavoriteCar } from '../../../../redux/slices/favoriteSlices';
import { addCarsCount } from '../../../../redux/slices/conutCarsSlices';
import { addCar } from '../../../../redux/slices/carModelSlices';
import { selectFavoriteCars } from '../../../../redux/slices/favoriteSlices';
// icons
import {
  FaCalendarDays,
  FaCarSide,
  FaGlobe,
  FaGaugeHigh,
} from 'react-icons/fa6';
import { PiEngineFill } from 'react-icons/pi';
import { FaExchangeAlt } from 'react-icons/fa';
import { FiArrowUpRight } from 'react-icons/fi';
import { RiInformation2Line } from 'react-icons/ri';
// default img
import defaultImg from '../../../../img/image.png';
// router-link
import { NavLink } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const CarsBox = ({ filterCarsByMark, modelCategory, inHasCars }) => {
  const dispatch = useDispatch();
  const favoriteCars = useSelector(selectFavoriteCars);

  const notify = () =>
    toast.success('Успешно добавлено в сравнение', {
      autoClose: 2000,
      position: 'top-left',
    });

  const notifyInfo = () =>
    toast.info('Уже добавлено', {
      autoClose: 2000,
      position: 'top-left',
    });

  const addCarInFavorite = (car) => {
    let isInArray = false;
    favoriteCars.forEach((date) => {
      if (date.idn === car.idn) {
        isInArray = true;
      }
    });
    if (!isInArray) {
      dispatch(addFavoriteCar(car));
      notify();
    } else {
      notifyInfo();
    }
  };

  const addCarModel = (car) => {
    dispatch(addCar(car));
  };

  const filterCarByModel =
    modelCategory !== 'Все модели'
      ? filterCarsByMark.filter((car) => {
          return car.carsName === modelCategory;
        })
      : filterCarsByMark;

  const cars =
    inHasCars !== false
      ? filterCarByModel.filter((car) => {
          return car.inner.toLowerCase() === 'в наличии';
        })
      : filterCarByModel;

  dispatch(addCarsCount(cars.length));
  return (
    <div className="cars-box">
      {cars.length > 0 ? (
        cars
          .map((car) => (
            <div className="car-box" key={car.idn}>
              <div className="car-img">
                {car.carsImg ? (
                  <img src={'./img/cars/' + car.carsImg} />
                ) : (
                  <img src={defaultImg} />
                )}
                <div className="car-inner">
                  {car.inner.toLowerCase() === 'в наличии' ? (
                    <div className="inner">
                      <span>{car.inner}</span>
                    </div>
                  ) : car.inner.toLowerCase() === 'в пути' ? (
                    <div className="inner-3">
                      <span>{car.inner}</span>
                    </div>
                  ) : (
                    <div className="inner-2">
                      <span>{car.inner}</span>
                    </div>
                  )}
                </div>
                <div className="produc-actions-btn">
                  <FaExchangeAlt
                    className="actions-icon"
                    onClick={() => addCarInFavorite(car)}
                  />
                </div>
              </div>
              {/* <Link
                to={`/models/${car.carsName}`}
                onClick={() => addCarModel(car)}
              > */}
              <div className="car-box-info relative">
                <div className="car-title">
                  <h3>{car.carsName}</h3>
                </div>
                <div className="car-info">
                  <p>
                    <FaCalendarDays className="cars-info-icon" />
                    <span>{car.carsDate}</span>
                  </p>
                  <p>
                    <PiEngineFill className="cars-info-icon" />
                    <span>{car.carsType}</span>
                  </p>
                  <p>
                    <FaCarSide className="cars-info-icon" />
                    <span>{car.carsDrive}</span>
                  </p>
                  <p>
                    <FaGlobe className="cars-info-icon" />
                    <span>{car.carsCountry}</span>
                  </p>
                  <p>
                    <FaGaugeHigh className="cars-info-icon" />
                    <span>{car.mileage}</span>
                  </p>
                </div>
                <div className="car-price">
                  <div>
                    {car.inner.toLowerCase() !== 'в наличии' ? (
                      <p>Цена под заказ:</p>
                    ) : (
                      <p>Цена в наличии:</p>
                    )}

                    <div className="flex items-center">
                      <h3>{car.carsPrice}</h3>
                      <RiInformation2Line
                        className="ml-[10px] price-info"
                        title="Цена в наличии отличается от заказа"
                      />
                    </div>
                  </div>
                  <NavLink
                    to={`/models/${car.carsName}`}
                    onClick={() => addCarModel(car)}
                  >
                    <span>
                      Подробнее <FiArrowUpRight />
                    </span>
                  </NavLink>
                </div>
              </div>
              {/* </Link> */}
            </div>
          ))
          .reverse()
      ) : (
        <div className="error-cars-text w-[100%] min-h-[20vh] flex justify-center items-center">
          <p className="text-center">
            <b> К сожалению, ничего не найдено </b> <br />
            <span>Но Вы можете закать</span>
          </p>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};
