import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectNewCars } from '../../../../redux/slices/newCarsSlisec';
import { selectUsedCars } from '../../../../redux/slices/usedCarsSlices';
import { Filters } from './Filters';
import { CarsBox } from './CarsBox';

export const AllCars = () => {
  const [carsCategory, setCarsCategory] = useState('Все марки');
  const [modelCategory, setModelCategory] = useState('Все модели');
  const [inHasCars, setInHasCars] = useState(false);

  const allCars = [
    ...useSelector(selectNewCars),
    ...useSelector(selectUsedCars),
  ];
  const filterModlesCar = (category) => {
    setModelCategory(category);
  };

  const filterCar = (category) => {
    setCarsCategory(category);
  };

  const toggleInHasCars = (e) => {
    setInHasCars(e.target.checked);
  };

  const filterCarsByMark =
    carsCategory !== 'Все марки'
      ? allCars.filter((car) => {
          return car.category === carsCategory;
        })
      : allCars;

  return (
    <div className="pt-[20px]">
      {allCars === false ? (
        <div className="loader h-[500px]"></div>
      ) : (
        <>
          <Filters
            cars={allCars}
            filterCar={filterCar}
            filterCarsByMark={filterCarsByMark}
            filterModlesCar={filterModlesCar}
            toggleInHasCars={toggleInHasCars}
          />
          <CarsBox
            filterCarsByMark={filterCarsByMark}
            modelCategory={modelCategory}
            inHasCars={inHasCars}
          />
        </>
      )}
    </div>
  );
};
