import { useEffect, useState } from 'react';
import { RiPlayCircleLine, RiCalendarLine } from 'react-icons/ri';
import { BlogModal } from '../../Home/BlogSection/BlogModal';
import { ImHome } from 'react-icons/im';
import { Link } from 'react-router-dom';
import { ImNewspaper } from 'react-icons/im';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const News = () => {
  const [data, setData] = useState([]);
  const [blogModal, setBlogModal] = useState(false);
  const [video, setVideo] = useState('');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    try {
      fetch('/api/3')
        .then((res) => res.json())
        .then((res) => {
          setData(res);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  function toggleBlogModal(url) {
    setBlogModal(!blogModal);
    setVideo(url);
    !blogModal
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'visible');
  }
  return (
    <div className="news">
      <ToastContainer />
      <div className="page-presentation page-presentation-news relative">
        <div className="container">
          <div className="title text-center">
            <h1>Блог</h1>
          </div>
          <div className="links">
            <ul className="flex">
              <li className="link flex items-center">
                <ImHome className="mr-[5px]" />
                <Link to="/">Главная</Link>
              </li>
              <li className="flex items-center">
                / <ImNewspaper className="mr-[5px] ml-[10px]" />
                <span>Новости</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="news-content">
          <div className="blog-content mt-[40px]">
            {data == false ? (
              <div className="loader h-[303px]"></div>
            ) : (
              <div className="blogs">
                <div className="flex flex-wrap justify-center">
                  {data.reverse().map((elem) => (
                    <div
                      className="blog-box mb-[30px] w-[320px] blog-box-ml"
                      key={elem.id}
                    >
                      <div className="img-blog-box">
                        <img
                          src={elem.img}
                          alt={elem.title}
                          className="h-[200px] w-[100%] rounded-[5px]"
                        />
                        <div className="img-font rounded-[5px]">
                          <RiPlayCircleLine
                            className="playIcon "
                            onClick={() => toggleBlogModal(elem.url)}
                          />
                        </div>
                      </div>
                      <div className="blog-date mt-[15px] flex justify-start items-center">
                        <RiCalendarLine className="blog-icon" />
                        <p className="ml-[10px] text-[16px] font-medium">
                          {elem.date}
                        </p>
                      </div>
                      <h3 className="mt-[10px]">{elem.title}</h3>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          <BlogModal
            blogModal={blogModal}
            toggleBlogModal={toggleBlogModal}
            video={video}
          />
        </div>
      </div>
    </div>
  );
};
