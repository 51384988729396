// reducer
import { useDispatch } from 'react-redux';
import { addPartsCount } from '../../../redux/slices/countPartsSlices';
import { addPart } from '../../../redux/slices/partModelSlices';
// icon
import { FiArrowUpRight } from 'react-icons/fi';
import { FaRegCircleCheck } from 'react-icons/fa6';
import { BsCircleHalf } from 'react-icons/bs';
// default img
import defaultImg from '../../../img/image.png';
// router-link
import { NavLink, Link } from 'react-router-dom';

export const BoxParts = ({ filterPart, inHasParts }) => {
  const dispatch = useDispatch();
  const addPartModel = (part) => {
    dispatch(addPart(part));
  };
  const parts =
    inHasParts !== false
      ? filterPart.filter((part) => {
          return part.inner.toLowerCase() === 'в наличии';
        })
      : filterPart;

  dispatch(addPartsCount(parts.length));
  return (
    <div className="cars-box">
      {parts.length > 0 ? (
        parts
          .map((part) => (
            <div className="parts-box" key={part.id}>
              <Link
                to={`/parts/${part.title}`}
                onClick={() => addPartModel(part)}
              >
                <div className="parts-img">
                  {part.img ? (
                    <img src={'./img/' + part.img} />
                  ) : (
                    <img src={defaultImg}></img>
                  )}
                  <div className="parts-inner">
                    {part.inner.toLowerCase() === 'в наличии' ? (
                      <div className="inner">
                        <span>{part.inner}</span>
                      </div>
                    ) : part.inner.toLowerCase() === 'в пути' ? (
                      <div className="inner-3">
                        <span>{part.inner}</span>
                      </div>
                    ) : (
                      <div className="inner-2">
                        <span>{part.inner}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="parts-box-info">
                  <div className="parts-title">
                    <h3>{part.title}</h3>
                  </div>
                  <div className="car-info">
                    <p>
                      <FaRegCircleCheck className="cars-info-icon" />
                      <span>{part.originality}</span>
                    </p>
                    {part.color ? (
                      <p>
                        <BsCircleHalf className="cars-info-icon" />
                        <span>{part.color}</span>
                      </p>
                    ) : (
                      false
                    )}
                  </div>
                  <div className="parts-price">
                    <div>
                      {part.inner.toLowerCase() !== 'в наличии' ? (
                        <p className="mb-[10px]">Цена под заказ:</p>
                      ) : (
                        <p className="mb-[10px]">Цена в наличии:</p>
                      )}
                      <div className="flex items-center">
                        <h3>{part.price}</h3>
                      </div>
                    </div>
                    <NavLink
                      to={`/parts/${part.title}`}
                      onClick={() => addPartModel(part)}
                    >
                      <span>
                        Подробнее <FiArrowUpRight />
                      </span>
                    </NavLink>
                  </div>
                </div>
              </Link>
            </div>
          ))
          .reverse()
      ) : (
        <div className="error-cars-text w-[100%] min-h-[20vh] flex justify-center items-center">
          <p className="text-center">
            <b> К сожалению, ничего не найдено </b> <br />
            <span>Но Вы можете заказать</span>
          </p>
        </div>
      )}
    </div>
  );
};
