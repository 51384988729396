import React, { useState } from 'react';
import Select from 'react-select';

export const FilterPars = ({ parts, setfilterParts }) => {
  const categoryParts = [
    ...new Set(
      parts.map((part) => {
        return part.forModel;
      })
    ),
  ];

  const carsOptions = categoryParts.map((category) => {
    return { value: category, label: category };
  });

  const updateCategory = (newCategory) => {
    setfilterParts(newCategory.value);
  };

  const options = [
    { value: 'Все запчасти', label: 'Все запчасти' },
    ...carsOptions,
  ];
  const customStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: state.isFocused ? '#134791' : 'grey',
      boxShadow: state.isFocused ? '#134791' : 'grey',
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : 'black',
      backgroundColor: state.isSelected ? '#134791' : 'white',
    }),
  };

  return (
    <div className="newCarsFilter">
      <Select
        options={options}
        noOptionsMessage={() => 'Ничего не найдено'}
        defaultValue={options[0]}
        onChange={updateCategory}
        className="w-[30%] z-50"
        isSearchable={options.length >= 15 ? true : false}
        isDisabled={false}
        styles={customStyles}
      />
    </div>
  );
};
