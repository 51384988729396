import React, { useEffect } from 'react';
import { Header } from './Header/Header';
import { Products } from './Products/Products';
import { BlogSection } from './BlogSection/BlogSection';
import { AboutSection } from './AboutSection/AboutSection';
import { FormSection } from '../UI/FormSection/FormSection';
import { ServicesSection } from './ServicesSection/ServicesSection';

export const Main = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main>
      <Header />
      <Products />
      <AboutSection />
      <ServicesSection />
      <FormSection title="Остались " span="вопросы?" />
      <BlogSection />
    </main>
  );
};
