import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCountCars } from '../../../../redux/slices/conutCarsSlices';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa6';
// import { FiSearch } from 'react-icons/fi';

export const Filters = ({
  cars,
  filterCar,
  filterCarsByMark,
  filterModlesCar,
  toggleInHasCars,
}) => {
  const [filterPosition, setFiterPosition] = useState('Все марки');
  const [filterModelPosition, setFiterModlePosition] = useState('Все модели');

  const [optionsIsActive, setOptions] = useState(false);
  const [modelOptionIsActive, setModelOption] = useState(false);
  const count = useSelector(selectCountCars);

  const markCategory = [
    ...new Set(
      cars.map((car) => {
        return car.category;
      })
    ),
  ];
  const modelCotegory = [
    ...new Set(
      filterCarsByMark.map((car) => {
        return car.carsName;
      })
    ),
  ];

  const toggleOptions = () => {
    setOptions(!optionsIsActive);
    setModelOption(false);
  };

  const toggleModelOption = () => {
    setModelOption(!modelOptionIsActive);
    setOptions(false);
  };

  const updateCategory = (newCategory) => {
    filterCar(newCategory);
    setFiterPosition(newCategory);
    setOptions(false);

    setFiterModlePosition('Все модели');
    filterModlesCar('Все модели');
  };

  const updateModelCategory = (newModelCategory) => {
    filterModlesCar(newModelCategory);
    setFiterModlePosition(newModelCategory);
    setModelOption(false);
  };

  return (
    <div className="filters-content">
      <div className="filters">
        {/* 1 */}
        <div className="mark-filter">
          <div className="filter" onClick={toggleOptions}>
            <span>{filterPosition}</span>
            {optionsIsActive === true ? <FaAngleUp /> : <FaAngleDown />}
          </div>
          <div
            className={
              optionsIsActive === true
                ? 'filter-options active'
                : 'filter-options'
            }
          >
            <ul>
              <li onClick={() => updateCategory('Все марки')}>Все марки</li>
              {markCategory.map((category, index) => (
                <li key={index} onClick={() => updateCategory(category)}>
                  {category}
                </li>
              ))}
            </ul>
          </div>
        </div>
        {/* 2 */}
        <div className="mark-filter ml">
          <div className="filter" onClick={toggleModelOption}>
            <span>{filterModelPosition}</span>
            {modelOptionIsActive === true ? <FaAngleUp /> : <FaAngleDown />}
          </div>
          <div
            className={
              modelOptionIsActive === true
                ? 'filter-options active'
                : 'filter-options'
            }
          >
            {filterPosition === 'Все марки' ? (
              <div className="flex justify-center h-[100%] items-center">
                <p>Выберите марку авто</p>
              </div>
            ) : (
              <ul>
                <li onClick={() => updateModelCategory('Все модели')}>
                  Все модели
                </li>
                {modelCotegory.map((category, index) => (
                  <li key={index} onClick={() => updateModelCategory(category)}>
                    {category}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        {/* 3 */}
        <div className="toggle-chekbox ml flex justify-between items-center w-[100%]">
          <div className="flex items-center">
            <input type="checkbox" onChange={(e) => toggleInHasCars(e)} />
            <span>Только в наличии</span>
          </div>
          <div className="carsCout ml-[30px]">
            <p>
              <span>
                Найдено: <b>{count}</b> авто
              </span>
            </p>
          </div>
        </div>
      </div>
      {/* <div className="seach-filter">
        <form>
          <input type="text" placeholder="Модель..." />
          <button type="submit">
            <FiSearch />
          </button>
        </form>
      </div> */}
    </div>
  );
};
