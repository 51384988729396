import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCountParts } from '../../../redux/slices/countPartsSlices';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa6';

export const FilterParts = ({ parts, filterPars, toggleInHasParts }) => {
  const [filterPosition, setFiterPosition] = useState('Все модели');
  const [optionsIsActive, setOptions] = useState(false);
  const count = useSelector(selectCountParts);

  const category = [
    ...new Set(
      parts.map((part) => {
        return part.forModel;
      })
    ),
  ];
  const toggleOptions = () => {
    setOptions(!optionsIsActive);
  };

  const updateCategory = (newCategory) => {
    filterPars(newCategory);
    setFiterPosition(newCategory);
    setOptions(false);
  };

  return (
    <div className="filters-content">
      <div className="filters">
        {/* 1 */}
        <div className="mark-filter ml">
          <div className="filter" onClick={toggleOptions}>
            <span>{filterPosition}</span>
            {optionsIsActive === true ? <FaAngleUp /> : <FaAngleDown />}
          </div>
          <div
            className={
              optionsIsActive === true
                ? 'filter-options active'
                : 'filter-options'
            }
          >
            <ul>
              <li onClick={() => updateCategory('Все модели')}>Все модели</li>
              {category.map((category, index) => (
                <li key={index} onClick={() => updateCategory(category)}>
                  Для {category}
                </li>
              ))}
            </ul>
          </div>
        </div>
        {/* 3 */}
        <div className="toggle-chekbox ml flex justify-between items-center w-[100%]">
          <div className="flex items-center">
            <input type="checkbox" onChange={(e) => toggleInHasParts(e)} />
            <span>Только в наличии</span>
          </div>
          <div className="carsCout ml-[30px]">
            <p>
              <span>
                Найдено: <b>{count}</b>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
