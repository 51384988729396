import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const partsSlices = createSlice({
  name: 'parts',
  initialState: initialState,
  reducers: {
    addParts: (state, action) => {
      return (state = action.payload);
    },
  },
});

export const { addParts } = partsSlices.actions;
export const selectParts = (state) => state.parts;

export default partsSlices.reducer;
