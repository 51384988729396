import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectParts } from '../../../redux/slices/partsSlices';
import { FilterParts } from './FilterParts';
import { BoxParts } from './BoxParts';
import { ImHome } from 'react-icons/im';
import { Link } from 'react-router-dom';
import { BiCog } from 'react-icons/bi';
import { FormSection } from '../../UI/FormSection/FormSection';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const PartsPage = () => {
  const [modelCategory, setModelCategory] = useState('Все модели');
  const [inHasParts, setInHasParts] = useState(false);
  const parts = useSelector(selectParts);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const filterPars = (category) => {
    setModelCategory(category);
  };

  const toggleInHasParts = (e) => {
    setInHasParts(e.target.checked);
  };

  const filterPart =
    modelCategory !== 'Все модели'
      ? parts.filter((part) => {
          return part.forModel === modelCategory;
        })
      : parts;

  return (
    <div className="parst-page">
      <ToastContainer />
      <div className="page-presentation-parts relative">
        <div className="container">
          <div className="title text-center">
            <h1>Запчасти</h1>
          </div>
          <div className="links">
            <ul className="flex">
              <li className="link flex items-center">
                <ImHome className="mr-[5px]" />
                <Link to="/">Главная</Link>
              </li>
              <li className="flex items-center">
                / <BiCog className="mr-[5px] ml-[10px]" />
                <span>Каталог запчастей</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="parts">
          {parts === false ? (
            <div className="loader h-[500px]"></div>
          ) : (
            <>
              <FilterParts
                parts={parts}
                toggleInHasParts={toggleInHasParts}
                filterPars={filterPars}
              />
              <BoxParts filterPart={filterPart} inHasParts={inHasParts} />
            </>
          )}
        </div>
      </div>
      <FormSection
        title="Привезем оригинальные запчасти"
        span="по лучшей цене"
      />
    </div>
  );
};
