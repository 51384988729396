import { useState } from 'react';
import Select from 'react-select';

export const FilterCars = ({ cars, filterCar }) => {
  const categoryCars = [
    ...new Set(
      cars.map((car) => {
        return car.category;
      })
    ),
  ];
  const carsOptions = categoryCars.map((category) => {
    return { value: category, label: category };
  });
  const options = [{ value: 'Все марки', label: 'Все марки' }, ...carsOptions];

  const handleChange = (selectOption) => {
    filterCar(selectOption.value);
  };
  const customStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: state.isFocused ? '#134791' : 'grey',
      boxShadow: state.isFocused ? '#134791' : 'grey',
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : 'black',
      backgroundColor: state.isSelected ? '#134791' : 'white',
    }),
  };

  return (
    <div className="newCarsFilter w-[100%]">
      <Select
        options={options}
        noOptionsMessage={() => 'Ничего не найдено'}
        defaultValue={options[0]}
        onChange={handleChange}
        className="w-[30%] z-50"
        isSearchable={options.length >= 15 ? true : false}
        isDisabled={false}
        styles={customStyles}
      />
    </div>
  );
};
