import { RiInformation2Line } from 'react-icons/ri';
// contac icon
import { FiPhone } from 'react-icons/fi';
import { FaRegClock } from 'react-icons/fa';
import { GoLocation } from 'react-icons/go';
import {
  BiCheckCircle,
  BiSolidPlaneTakeOff,
  BiSolidHourglass,
} from 'react-icons/bi';
import { Button } from '../../UI/Button';
import { useDispatch, useSelector } from 'react-redux';
import {
  toggleForm,
  selectToggleModal,
} from '../../../redux/slices/modalToggleSlices';

export const PartsContactBlock = ({ part }) => {
  const dispatch = useDispatch();
  const toggleModal = useSelector(selectToggleModal);

  const toggleFormModal = () => {
    dispatch(toggleForm());
    !toggleModal
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'visible');
  };

  return (
    <div className="part-price">
      <div className="price mt-[20px] mb-[20px]">
        <h3> {part.price}</h3>
        {part.inner.toLowerCase() === 'в наличии' ? (
          <p className="mt-[10px] flex items-center">
            <BiCheckCircle className="price-info text-green-600" />
            <span className="ml-[5px] text-green-600">{part.inner}</span>
          </p>
        ) : part.inner.toLowerCase() === 'в пути' ? (
          <p className="mt-[10px] flex items-center">
            <BiSolidPlaneTakeOff className="price-info text-yellow-600" />
            <span className="ml-[5px] text-yellow-600">{part.inner}</span>
          </p>
        ) : (
          <p className="mt-[10px] flex items-center">
            <BiSolidPlaneTakeOff className="price-info text-red-600" />
            <span className="ml-[5px] text-red-600">{part.inner}</span>
          </p>
        )}
        <p className="mt-[10px] flex items-center">
          <BiSolidHourglass className="price-info" />
          <span className="ml-[5px]">Срок доставки: {part.delivery}</span>
        </p>
        <p className="mt-[10px] flex items-center">
          <RiInformation2Line className="price-info" />
          <span className="ml-[5px]">Доставка по РФ платная</span>
        </p>
        <div className="model-contact">
          <h4>Контакты</h4>
          <ul>
            <li>
              <FiPhone className="icon-bar" />
              <a href="tel:+78047004041">+7(804)700-40-41</a>
            </li>
            <li>
              <FaRegClock className="icon-bar" />
              <span>Пн - Вс 10:00 - 20:00</span>
            </li>
            <li>
              <GoLocation className="icon-bar" />
              <a
                href="https://yandex.ru/maps/org/neofitsal_avto/110534909234/?ll=49.180748%2C55.788152&z=18"
                target="_blank"
              >
                г. Казань. ул. Седова 24к1
              </a>
            </li>
          </ul>
        </div>
        <div onClick={toggleFormModal}>
          <Button url="" title="Заказать" />
        </div>
      </div>
    </div>
  );
};
