// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
// import required modules
import { Navigation, Pagination } from 'swiper/modules';
// reducer
import { useDispatch } from 'react-redux';
import { addFavoriteCar } from '../../../../redux/slices/favoriteSlices';
import { addCar } from '../../../../redux/slices/carModelSlices';
// icons
import {
  FaCalendarDays,
  FaCarSide,
  FaGlobe,
  FaGaugeHigh,
} from 'react-icons/fa6';

import { PiEngineFill } from 'react-icons/pi';
import { FaExchangeAlt } from 'react-icons/fa';
import { FiArrowUpRight } from 'react-icons/fi';
import { RiInformation2Line } from 'react-icons/ri';

import { useSelector } from 'react-redux';
import { selectFavoriteCars } from '../../../../redux/slices/favoriteSlices';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// default img
import defaultImg from '../../../../img/image.png';
// router-link
import { NavLink, Link } from 'react-router-dom';

export const NewCarsBox = ({ filterCars }) => {
  const dispatch = useDispatch();
  const favoriteCars = useSelector(selectFavoriteCars);

  const notify = () =>
    toast.success('Успешно добавлено в сравнение', {
      autoClose: 2000,
      position: 'top-left',
    });

  const notifyInfo = () =>
    toast.info('Уже добавлено', {
      autoClose: 2000,
      position: 'top-left',
    });

  const addCarInFavorite = (car) => {
    let isInArray = false;
    favoriteCars.forEach((date) => {
      if (date.idn === car.idn) {
        isInArray = true;
      }
    });
    if (!isInArray) {
      dispatch(addFavoriteCar(car));
      notify();
    } else {
      notifyInfo();
    }
  };

  const addCarModel = (car) => {
    dispatch(addCar(car));
  };

  return (
    <div className="new-cars-boxs">
      <ToastContainer />
      <Swiper
        slidesPerView={
          window.innerWidth <= 700
            ? 1
            : window.innerWidth <= 1050
            ? 2
            : window.innerWidth <= 1250
            ? 3
            : window.innerWidth <= 1550
            ? 4
            : window.innerWidth > 1250
            ? 4
            : 0
        }
        spaceBetween={30}
        grabCursor={true}
        loop={true}
        navigation={true}
        pagination={{
          dynamicBullets: true,
        }}
        modules={[Navigation, Pagination]}
        className="mySwiper"
      >
        {filterCars
          .map((car) => (
            <SwiperSlide key={car.idn}>
              <div className="car-box">
                <div className="car-img">
                  {car.carsImg ? (
                    <img src={'../img/cars/' + car.carsImg}></img>
                  ) : (
                    <img src={defaultImg}></img>
                  )}
                  <div className="car-inner">
                    {car.inner.toLowerCase() === 'в наличии' ? (
                      <div className="inner">
                        <span>{car.inner}</span>
                      </div>
                    ) : car.inner.toLowerCase() === 'в пути' ? (
                      <div className="inner-3">
                        <span>{car.inner}</span>
                      </div>
                    ) : (
                      <div className="inner-2">
                        <span>{car.inner}</span>
                      </div>
                    )}
                  </div>
                  <div className="produc-actions-btn">
                    <FaExchangeAlt
                      className="actions-icon"
                      onClick={() => addCarInFavorite(car)}
                    />
                  </div>
                </div>
                {/* <Link
                  to={`/models/${car.carsName}`}
                  onClick={() => addCarModel(car)}
                > */}
                <div className="car-box-info">
                  <div className="car-title">
                    <h3>{car.carsName}</h3>
                  </div>
                  <div className="car-info">
                    <p>
                      <FaCalendarDays className="cars-info-icon" />
                      <span>{car.carsDate}</span>
                    </p>
                    <p>
                      <PiEngineFill className="cars-info-icon" />
                      <span>{car.carsType}</span>
                    </p>
                    <p>
                      <FaCarSide className="cars-info-icon" />
                      <span>{car.carsDrive}</span>
                    </p>
                    <p>
                      <FaGlobe className="cars-info-icon" />
                      <span>{car.carsCountry}</span>
                    </p>
                    <p>
                      <FaGaugeHigh className="cars-info-icon" />
                      <span>{car.mileage}</span>
                    </p>
                  </div>
                  <div className="car-price">
                    <div>
                      {car.inner.toLowerCase() !== 'в наличии' ? (
                        <p>Цена под заказ:</p>
                      ) : (
                        <p>Цена в наличии:</p>
                      )}

                      <div className="flex items-center">
                        <h3>{car.carsPrice}</h3>
                        <RiInformation2Line
                          className="ml-[10px] price-info"
                          title="Цена в наличии отличается от заказа"
                        />
                      </div>
                    </div>
                    <NavLink
                      to={`/models/${car.carsName}`}
                      onClick={() => addCarModel(car)}
                    >
                      <span>
                        Подробнее <FiArrowUpRight />
                      </span>
                    </NavLink>
                  </div>
                </div>
                {/* </Link> */}
              </div>
            </SwiperSlide>
          ))
          .reverse()}
      </Swiper>
    </div>
  );
};
