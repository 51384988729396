import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import defImg from '../../../img/image.png';

export const PartGallery = ({ part }) => {
  const partImgs = [part.img, part.img2, part.img3, part.img4];
  const imgs = [];
  partImgs.filter((img) => {
    if (img !== null) {
      return imgs.push({
        src: '../img/' + img,
      });
    }
  });
  return (
    <div className="car-imgs-block">
      <Carousel images={imgs} />
    </div>
  );
};
