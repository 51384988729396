import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUsedCars } from '../../../../redux/slices/usedCarsSlices';
import { Button } from '../../../UI/Button';
import { NewCarsBox } from './NewCarsBox';
import { FilterCars } from './FilterCars';

export const UsedCars = () => {
  const cars = useSelector(selectUsedCars);
  const [carsCategory, setCarsCategory] = useState('Все марки');

  const filterCar = (category) => {
    setCarsCategory(category);
  };

  const filterCars =
    carsCategory !== 'Все марки'
      ? cars.filter((car) => {
          return car.category === carsCategory;
        })
      : cars;

  return (
    <div className="cars">
      {cars == false ? (
        <div className="loader h-[303px]"></div>
      ) : (
        <div className="new-cars-content">
          <FilterCars cars={cars} filterCar={filterCar} />
          <NewCarsBox filterCars={filterCars} />
          <div className="flex justify-center pt-[20px]">
            <Button url="models" title="Все модели" />
          </div>
        </div>
      )}
    </div>
  );
};
