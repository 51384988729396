// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
// import required modules
import { Navigation, Pagination } from 'swiper/modules';
// icon
import { FiArrowUpRight } from 'react-icons/fi';
import { FaRegCircleCheck } from 'react-icons/fa6';
import { BsCircleHalf } from 'react-icons/bs';
// default img
import defaultImg from '../../../../img/image.png';
// router-link
import { NavLink, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addPart } from '../../../../redux/slices/partModelSlices';

export const PartsBox = ({ filterParts }) => {
  const dispatch = useDispatch();

  const addPartModel = (part) => {
    dispatch(addPart(part));
  };

  return (
    <div className="new-cars-boxs">
      <Swiper
        slidesPerView={
          window.innerWidth <= 700
            ? 1
            : window.innerWidth <= 1050
            ? 2
            : window.innerWidth <= 1250
            ? 3
            : window.innerWidth <= 1550
            ? 4
            : window.innerWidth > 1250
            ? 4
            : 0
        }
        spaceBetween={30}
        grabCursor={true}
        loop={true}
        navigation={true}
        pagination={{
          dynamicBullets: true,
        }}
        modules={[Navigation, Pagination]}
        className="mySwiper"
      >
        {filterParts
          .map((part) => (
            <SwiperSlide key={part.idn}>
              {/* <Link
                to={`/parts/${part.title}`}
                onClick={() => addPartModel(part)}
              > */}
              <div className="parts-box">
                <div className="parts-img">
                  {part.img ? (
                    <img src={'../img/' + part.img}></img>
                  ) : (
                    <img src={defaultImg}></img>
                  )}
                  <div className="parts-inner">
                    {part.inner.toLowerCase() === 'в наличии' ? (
                      <div className="inner">
                        <span>{part.inner}</span>
                      </div>
                    ) : part.inner.toLowerCase() === 'в пути' ? (
                      <div className="inner-3">
                        <span>{part.inner}</span>
                      </div>
                    ) : (
                      <div className="inner-2">
                        <span>{part.inner}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="parts-box-info">
                  <div className="parts-title">
                    <h3>{part.title}</h3>
                  </div>
                  <div className="car-info">
                    <p>
                      <FaRegCircleCheck className="cars-info-icon" />
                      <span>{part.originality}</span>
                    </p>
                    {part.color ? (
                      <p>
                        <BsCircleHalf className="cars-info-icon" />
                        <span>{part.color}</span>
                      </p>
                    ) : (
                      false
                    )}
                  </div>
                  <div className="parts-price">
                    <div>
                      {part.inner.toLowerCase() !== 'в наличии' ? (
                        <p className="mb-[10px]">Цена под заказ:</p>
                      ) : (
                        <p className="mb-[10px]">Цена в наличии:</p>
                      )}
                      <div className="flex items-center">
                        <h3>{part.price}</h3>
                      </div>
                    </div>
                    <NavLink
                      to={`/parts/${part.title}`}
                      onClick={() => addPartModel(part)}
                    >
                      <span>
                        Подробнее <FiArrowUpRight />
                      </span>
                    </NavLink>
                  </div>
                </div>
              </div>
              {/* </Link> */}
            </SwiperSlide>
          ))
          .reverse()}
      </Swiper>
    </div>
  );
};
