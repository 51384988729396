import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const usedCarsSlices = createSlice({
  name: 'usedCars',
  initialState: initialState,
  reducers: {
    addUsedCars: (state, action) => {
      return (state = action.payload);
    },
  },
});

export const { addUsedCars } = usedCarsSlices.actions;
export const selectUsedCars = (state) => state.usedCars;

export default usedCarsSlices.reducer;
