import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const carModelSlices = createSlice({
  name: 'carModel',
  initialState: initialState,
  reducers: {
    addCar: (state, action) => {
      return (state = action.payload);
    },
  },
});

export const { addCar } = carModelSlices.actions;
export const selectCarModel = (state) => state.carModel;

export default carModelSlices.reducer;
